import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { updateDoc, doc } from 'firebase/firestore';
import { GetDB } from '../../../utils/database/firebase-budget-tool';
import PassportList from '../passport/passportList';
import CountryWeightInput from '../passport/countryWeightInput';

const CountryDetails = ({ country, passports, onClose }) => {
    const [unsavedChanges, setUnsavedChanges] = useState({
        information: country.information || '',
        internalWeight: country.internalWeight || 1
    });

    const db = GetDB();

    useEffect(() => {
        // Update local state when country prop changes
        setUnsavedChanges({
            information: country.information || '',
            internalWeight: country.internalWeight || 1
        });
    }, [country]);

    const handleUpdate = async (countryId, field, value) => {
        try {
            if (field === 'internalWeight') {
                value = Math.max(0, Math.min(100, value));
            }
            
            await updateDoc(doc(db, 'Countries', countryId), {
                [field]: value
            });
        } catch (error) {
            console.error("Error updating country:", error);
        }
    };

    const handlePassportSelect = (option, fieldName, country) => {
        if (!option) return;
        
        // Check if the passport is already in any visa requirement list
        const isInAnyList = ['noVisaRequired', 'visaOnArrival', 'eVisaRequired', 'paperVisaRequired', 'restrictedPassports']
            .some(list => country[list]?.includes(option.value));
        
        if (isInAnyList) {
            alert('This passport is already assigned to a visa requirement category');
            return;
        }

        const currentList = country[fieldName] || [];
        if (!currentList.includes(option.value)) {
            handleUpdate(country.id, fieldName, [...currentList, option.value]);
        }
    };

    const handlePassportRemove = (passportId, fieldName, country) => {
        const currentList = country[fieldName] || [];
        handleUpdate(country.id, fieldName, currentList.filter(id => id !== passportId));
    };

    const handleInformationChange = (e) => {
        if (!e || !e.target) return;
        const newValue = e.target.value || '';
        setUnsavedChanges(prev => ({
            ...prev,
            information: newValue
        }));
    };

    const handleSaveChanges = async () => {
        try {
            await handleUpdate(country.id, 'information', unsavedChanges.information);
            await handleUpdate(country.id, 'internalWeight', unsavedChanges.internalWeight);
        } catch (error) {
            console.error("Error saving changes:", error);
        }
    };

    return (
        <CountryDetailsPanel>
            <CountryDetailsHeader>
                <div>
                    <h3>{country.country}</h3>
                    <SaveButton 
                        onClick={handleSaveChanges}
                        hasChanges={unsavedChanges.information !== country.information || 
                                  unsavedChanges.internalWeight !== country.internalWeight}
                    >
                        Save Changes
                    </SaveButton>
                </div>
                <CloseButton onClick={onClose}>×</CloseButton>
            </CountryDetailsHeader>
            <CountryDetailsContent>
                <CountryWeightInput
                    weight={unsavedChanges.internalWeight}
                    onChange={(value) => {
                        setUnsavedChanges(prev => ({ ...prev, internalWeight: value }));
                    }}
                />
                <InformationSection>
                    <h6>Information</h6>
                    <InformationTextArea
                        value={unsavedChanges.information}
                        onChange={handleInformationChange}
                        placeholder="Add information about the country..."
                    />
                </InformationSection>
                <PassportList 
                    title="No Visa Required" 
                    fieldName="noVisaRequired" 
                    passportIds={country.noVisaRequired}
                    country={country}
                    passports={passports}
                    onPassportSelect={handlePassportSelect}
                    onPassportRemove={handlePassportRemove}
                />
                <PassportList 
                    title="Visa On Arrival" 
                    fieldName="visaOnArrival" 
                    passportIds={country.visaOnArrival}
                    country={country}
                    passports={passports}
                    onPassportSelect={handlePassportSelect}
                    onPassportRemove={handlePassportRemove}
                />
                <PassportList 
                    title="eVisa/ETA Required" 
                    fieldName="eVisaRequired" 
                    passportIds={country.eVisaRequired}
                    country={country}
                    passports={passports}
                    onPassportSelect={handlePassportSelect}
                    onPassportRemove={handlePassportRemove}
                />
                <PassportList 
                    title="Paper Visa Required" 
                    fieldName="paperVisaRequired" 
                    passportIds={country.paperVisaRequired}
                    country={country}
                    passports={passports}
                    onPassportSelect={handlePassportSelect}
                    onPassportRemove={handlePassportRemove}
                />
                <PassportList 
                    title="Restricted Passports" 
                    fieldName="restrictedPassports" 
                    passportIds={country.restrictedPassports}
                    country={country}
                    passports={passports}
                    onPassportSelect={handlePassportSelect}
                    onPassportRemove={handlePassportRemove}
                />
            </CountryDetailsContent>
        </CountryDetailsPanel>
    );
};

const CountryDetailsPanel = styled.div`
    width: 500px;
    background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: white;
`;

const CountryDetailsHeader = styled.div`
    padding: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h3 {
        margin: 0;
        color: white;
        margin-bottom: 8px;
    }
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
    padding: 0 8px;
    line-height: 1;

    &:hover {
        color: ${props => props.theme.primaryAccent};
    }
`;

const CountryDetailsContent = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    background: rgba(255, 255, 255, 0.05);
`;

const InformationSection = styled.div`
    margin-bottom: 20px;
    
    h6 {
        margin-bottom: 8px;
        color: white;
    }
`;

const InformationTextArea = styled.textarea`
    width: 100%;
    min-height: 100px;
    padding: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.9);
    background: rgba(255, 255, 255, 0.1);
    resize: vertical;

    &:focus {
        outline: none;
        border-color: ${props => props.theme.primaryAccent};
        box-shadow: 0 0 0 1px ${props => props.theme.primaryAccent};
    }

    &::placeholder {
        color: rgba(255, 255, 255, 0.5);
    }
`;

const SaveButton = styled.button`
    background: ${props => props.hasChanges ? props.theme.primaryAccent : 'rgba(255, 255, 255, 0.1)'};
    border: none;
    color: white;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: ${props => props.hasChanges ? 'pointer' : 'default'};
    font-size: 14px;
    transition: all 0.2s ease;

    &:hover {
        ${props => props.hasChanges && `
            background: ${props.theme.primary};
        `}
    }
`;

export default CountryDetails; 