// countryCard.jsx
import React from 'react';
import { Colors } from '../../../utils/budget-tool-specific';
import styled from 'styled-components';

const CountryCard = ({ 
    country, 
    isSelected, 
    onSelect
}) => {
    return (
        <CardContainer isSelected={isSelected} onClick={onSelect}>
            <span>{country.country}</span>
        </CardContainer>
    );
};

const CardContainer = styled.div`
    background: ${props => props.isSelected 
        ? Colors.primaryAccent 
        : '#ffffff'};
    color: ${props => props.isSelected ? Colors.primaryDark : Colors.primaryDark};
    padding: 12px;
    border-radius: 6px;
    cursor: ${props => props.isSelected ? 'default' : 'pointer'};
    text-align: center;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    height: 60px;
    position: relative;
    border: 2px solid ${props => props.isSelected ? Colors.primaryAccent : '#d0d0d0'};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
    /* Support for exactly two lines of text with ellipsis */
    & > span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.6;
        word-break: break-word;
        width: 100%;
        padding: 2px 0;
        min-height: 3.2em;
    }

    &:hover {
        ${props => !props.isSelected && `
            background: #ffffff;
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
            border-color: ${Colors.primaryDark}40;
        `}
    }
`;

export default CountryCard;