// settings-passport.jsx
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { WhiteForm, Colors, PrimaryButton } from '../../../utils/budget-tool-specific';
import { GetDB } from '../../../utils/database/firebase-budget-tool';
import { collection, setDoc, addDoc, updateDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { doc } from 'firebase/firestore';  // Add this import
import styled from 'styled-components';
import { Container, ActionButtonContainer, SeedButton } from '../passport/commonComponents';
import VisaWeightsManager from './visaWeightsManager';
import SettingsPassportCard from './settingsPassportCard';
import { countries as countriesData } from './countries';

const SettingsPassport = () => {
    const [passports, setPassports] = useState([]);
    const [visaWeights, setVisaWeights] = useState({
        noVisa: 1000,
        visaOnArrival: 100,
        eVisa: 10,
        paperVisa: 1,
        restricted: 0
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingPassport, setEditingPassport] = useState(null);
    const [formData, setFormData] = useState({
        country: '',
        code: '',
        region: '',
        notes: ''
    });
    const [searchQuery, setSearchQuery] = useState('');

    const db = GetDB();

    useEffect(() => {
        const unsubscribePassports = onSnapshot(collection(db, 'Passports'), (snapshot) => {
            const passportData = [];
            snapshot.forEach((docSnapshot) => {
                passportData.push({ id: docSnapshot.id, ...docSnapshot.data() });
            });
            setPassports(passportData);
        });
    
        const unsubscribeWeights = onSnapshot(doc(db, 'Settings', 'visaWeights'), (docSnapshot) => {
            if (docSnapshot.exists()) {
                setVisaWeights(docSnapshot.data());
            } else {
                // Initialize weights if they don't exist
                setDoc(doc(db, 'Settings', 'visaWeights'), visaWeights);
            }
        });
    
        return () => {
            unsubscribePassports();
            unsubscribeWeights();
        };
    }, []);

    const seedDatabase = async () => {
        try {
            for (const country of countriesData) {
                try {
                    const docRef = doc(db, 'Passports', country.code);
                    await setDoc(docRef, {
                        country: country.name,
                        code: country.code,
                        region: country.region,
                        notes: `Capital: ${country.capital}\nCurrency: ${country.currency.name}\nLanguage: ${country.language.name}`
                    });
                } catch (error) {
                    console.error(`Error adding ${country.name}:`, error);
                }
            }
            alert('Passport database seeded successfully!');
        } catch (error) {
            console.error('Error seeding database:', error);
            alert('Error seeding database. Check console for details.');
        }
    };

    const handleWeightChange = async (type, value) => {
        try {
            await setDoc(doc(db, 'Settings', 'visaWeights'), {
                ...visaWeights,
                [type]: value
            });
        } catch (error) {
            console.error("Error updating weights:", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        try {
            if (editingPassport) {
                await updateDoc(doc(db, 'Passports', editingPassport.id), formData);
            } else {
                await addDoc(collection(db, 'Passports'), formData);
            }
            setIsModalOpen(false);
            resetForm();
        } catch (error) {
            console.error("Error saving passport:", error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteDoc(doc(db, 'Passports', id));
        } catch (error) {
            console.error("Error deleting passport:", error);
        }
    };

    const resetForm = () => {
        setFormData({
            country: '',
            code: '',
            region: '',
            notes: ''
        });
        setEditingPassport(null);
    };

    const filteredPassports = passports.filter(passport =>
        passport.country.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Container>
            <VisaWeightsManager 
                weights={visaWeights}
                onWeightChange={handleWeightChange}
            />
            
            <Row className="mb-4">
                <Col>
                    <h4>Passport Database</h4>
                </Col>
                <Col className="text-end">
                    <ActionButtonContainer>
                        <PrimaryButton onClick={() => {
                            resetForm();
                            setIsModalOpen(true);
                        }}>
                            Add Passport
                        </PrimaryButton>
                        {/*}
                        <SeedButton onClick={seedDatabase}>
                            Seed Database
                        </SeedButton>
                        */}
                    </ActionButtonContainer>
                </Col>
            </Row>

            <SearchContainer>
                <SearchInput 
                    type="text"
                    placeholder="Search passports..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </SearchContainer>

            <PassportsGrid>
                {filteredPassports.map((passport) => (
                    <SettingsPassportCard
                        key={passport.id}
                        passport={passport}
                        onEdit={() => {
                            setEditingPassport(passport);
                            setFormData(passport);
                            setIsModalOpen(true);
                        }}
                        onDelete={() => handleDelete(passport.id)}
                    />
                ))}
            </PassportsGrid>

            <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
                <ModalHeader toggle={() => setIsModalOpen(false)}>
                    {editingPassport ? 'Edit Passport' : 'Add Passport'}
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label>Country Name</Label>
                            <Input
                                name="country"
                                value={formData.country}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Country Code</Label>
                            <Input
                                name="code"
                                value={formData.code}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Region</Label>
                            <Input
                                name="region"
                                value={formData.region}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Notes</Label>
                            <Input
                                type="textarea"
                                name="notes"
                                value={formData.notes}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <PrimaryButton onClick={handleSubmit}>
                        {editingPassport ? 'Update' : 'Add'} Passport
                    </PrimaryButton>{' '}
                    <Button color="secondary" onClick={() => setIsModalOpen(false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
};

const SearchContainer = styled.div`
    margin-bottom: 20px;
`;

const SearchInput = styled(Input)`
    max-width: 300px;
    border-radius: 4px;
    padding: 8px 12px;
    
    &:focus {
        border-color: ${Colors.primaryAccent};
        box-shadow: 0 0 0 1px ${Colors.primaryAccent};
        outline: none;
    }
`;

const PassportsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 10px;
`;

export default SettingsPassport;