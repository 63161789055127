import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, 
        Col,
        Container,
        Navbar,
        Collapse,
        } from "reactstrap";
import { Colors, NavItems, HeaderIcon, Spacer } from "../../utils/budget-tool-specific";
import MonikerIcon from '../../assets/images/moniker-logo-white.png';
import NavItem from "./nav-item";
import { findHeaderSize } from "./helper";


const Header = (props) => {
    const [headerSize, setHeaderSize] = useState();

    const getNavItemElement = (item, key, collapsed) => {
        let path = window.location.pathname;
        let fontColor = Colors.primaryXLight;
        if (path.includes(item[1].urlify)) {
            fontColor = Colors.primaryAccent;
        }
        return (
            <NavItem item={item[1]} fontColor={fontColor} key={key} collapsed={props.collapsed}/>
        ) 
    }

    useEffect(() => {
        if (props.windowSize.innerWidth > 1365) {
            setHeaderSize("1365px");
        } else if (props.windowSize.innerWidth > 1200) {
            setHeaderSize(props.windowSize.innerWidth + "px");
            props.setCollapsed(false);
        } else if (props.windowSize.innerWidth > 712) {
            setHeaderSize(props.windowSize.innerWidth + "px");
            props.setCollapsed(false);
        } else { 
            setHeaderSize("365px");
            props.setCollapsed(true);
        }
    }, [props.windowSize.innerWidth]);
    
    const navigateHome = () => {
        if (!props.client) {
            window.location = "/budget-app";
        }
    }

    return (
        <React.Fragment>
            <StyledNavbar>
                <Banner>
                    <BannerImage src={MonikerIcon} onClick={() => navigateHome()}/>
                    {props.collapsed && !props.client && <div style={{marginLeft: "140px", display: "flex"}}>
                    {Object.entries(NavItems).map((item, key) => {
                        return (
                            getNavItemElement(item, key)
                        );
                    })}
                    </div>}
                </Banner>
            </StyledNavbar>
            {!props.collapsed && <NavCol>
                <Spacer height="85px"/>
                {!props.client && Object.entries(NavItems).map((item, key) => {
                    return (
                        getNavItemElement(item, key)
                    );
                })}
                <VerticalSwooshImage src={"https://cdn.moniker-virtual.com/images/banners/vertical-swoosh.png"}/>
            </NavCol> }
        </React.Fragment>
    )
}

export default Header;
const StyledNavbar = styled(Navbar)`
    position: fixed;
    z-index: 1000;
    width: 100vw;
    padding: 0px;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(46,46,46,1) 92%, rgba(59,59,59,1) 100%);
    display: flex;
    justify-content: flex-start;
`

const NavCol = styled.div`
    position: fixed;
    width: 190px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: rgb(0,0,0);
    background: linear-gradient(150deg, rgba(0,0,0,1) 30%, rgba(50,50,50,1) 60%, rgba(91,91,91,1) 100%);
`
const Banner = styled.div`
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
`

const BannerImage = styled.img`
    height: 25px;
    margin: 15px;
    z-index: 1002;
    position: relative;
    &:hover {
        cursor: pointer;
    }
`

const NavHomeDiv = styled.div`
    width: 140px;
    height: 60px;
`;

const VerticalSwooshImage = styled.img`
    position: fixed;
    height: 100%;
    width: 190px;
    object-fit: none;
    z-index: 1000;
`;