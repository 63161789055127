import React from 'react';
import { Row, Col } from "reactstrap";
import { OutputRow, TableIcon, BoldLabel, ButtonContainer, CollapseButton } from "../../../../utils/budget-tool-specific";
import { displayValue } from '../../helper';
import SubtotalCols from './subtotal-cols';

const CostRow = (props) => {

    const normalStyle = {
        transition: '1s, transform 0.5s',
    };
      
      const transformedStyle = {
        transition: '1s, transform 0.5s',
        transform: 'rotate(180deg)',
    };

    const AccommodationStructure = () => {
        return(
            <React.Fragment>
                <br />
                {props.accommodation.singleRooms.value > 0 && 
                    <React.Fragment>
                        <span>&gt; {props.accommodation.singleRooms.value}x SGL Rooms @ </span>
                        <span>{props.currency}{displayValue(props.exchangeRate(props.accommodation, props.accommodation.singleRate.value))}/rm per night</span>
                    </React.Fragment>}
                {props.accommodation.singleRooms.value > 0 && props.accommodation.doubleRooms.value > 0 && <br />}
                {props.accommodation.doubleRooms.value > 0 && 
                    <React.Fragment>
                        <span>&gt; {props.accommodation.doubleRooms.value}x DBL Rooms @ </span>
                        <span>{props.currency}{displayValue(props.exchangeRate(props.accommodation, props.accommodation.doubleRate.value))}/rm per night</span>
                    </React.Fragment>}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <OutputRow>
                <Col lg="1" style={{textAlign: "center", alignItems: "center", verticalAlign: "middle"}}>
                    {!props.single && <ButtonContainer onClick={() => {props.setIsOpen(!props.isOpen)}}>
                        <CollapseButton icon={['fas', 'chevron-down']} style={props.isOpen ? transformedStyle : normalStyle}/> 
                    </ButtonContainer>}
                </Col>
                <Col>
                    <Row>
                        <Col sm={{size: 'auto'}}>
                            <TableIcon icon={['fas', props.icon]} style={{height: props.height ? "30px" : "auto"}}/>
                        </Col>
                        <Col>
                        <BoldLabel>
                            {props.title}
                        </BoldLabel>
                        </Col>
                    </Row>
                </Col>
                {props.percent && <Col lg="1">
                    {props.percent}%
                </Col>}
                {props.single && <Col>
                    {props.type && !props.detail && <p>
                        {props.currency}{displayValue(parseFloat(props.costPer))} {props.accommodation && 'AVG/pp'} {props.perLabel} x {props.num} {props.type} {props.message ? `(${props.message})` : ""} {props.accommodation && <AccommodationStructure />}
                    </p>}
                    {!props.type && !props.detail && <p>
                        {props.currency}{displayValue(parseFloat(props.costPer))} {props.perLabel}
                    </p>}
                </Col>}
                {!props.single && <Col></Col>}
                <Col lg="2">
                    <p >
                        {props.currency}{displayValue(parseFloat(props.subtotal))}
                    </p>
                </Col>         
                <SubtotalCols currency={props.currency} attendees={props.attendees} costper={props.subtotal}/>
            </OutputRow>
        </React.Fragment>
    )
}

export default CostRow;