// comparePassports.js
export const comparePassports = (passports, selectedPassports, countries, travelerCounts) => {
    console.log('Comparing passports with data:', { passports, selectedPassports, countries, travelerCounts });
    
    const selectedPassportData = passports.filter(p => selectedPassports.includes(p.id));
    console.log('Selected passport data:', selectedPassportData);
    
    const results = countries.map(country => {
        const noVisa = selectedPassportData.filter(p => 
            country.noVisaRequired?.includes(p.id)
        );
        const visaOnArrival = selectedPassportData.filter(p => 
            country.visaOnArrival?.includes(p.id)
        );
        const eVisa = selectedPassportData.filter(p => 
            country.eVisaRequired?.includes(p.id)
        );
        const paperVisa = selectedPassportData.filter(p => 
            country.paperVisaRequired?.includes(p.id)
        );

        const noVisaCount = noVisa.length;
        const visaOnArrivalCount = visaOnArrival.length;
        const eVisaCount = eVisa.length;
        const paperVisaCount = paperVisa.length;

        // Calculate weighted scores based on passport weights and traveler counts
        const noVisaWeight = noVisa.reduce((sum, p) => {
            const travelerCount = travelerCounts[p.id] || 1;
            const weight = (p.internalWeight || 1) * travelerCount;
            console.log(`No visa weight for ${p.country}: ${weight} (${p.internalWeight || 1} × ${travelerCount} travelers)`);
            return sum + weight;
        }, 0);

        const visaOnArrivalWeight = visaOnArrival.reduce((sum, p) => {
            const travelerCount = travelerCounts[p.id] || 1;
            const weight = (p.internalWeight || 1) * travelerCount;
            console.log(`Visa on arrival weight for ${p.country}: ${weight} (${p.internalWeight || 1} × ${travelerCount} travelers)`);
            return sum + weight;
        }, 0);

        const eVisaWeight = eVisa.reduce((sum, p) => {
            const travelerCount = travelerCounts[p.id] || 1;
            const weight = (p.internalWeight || 1) * travelerCount;
            console.log(`eVisa weight for ${p.country}: ${weight} (${p.internalWeight || 1} × ${travelerCount} travelers)`);
            return sum + weight;
        }, 0);

        const paperVisaWeight = paperVisa.reduce((sum, p) => {
            const travelerCount = travelerCounts[p.id] || 1;
            const weight = (p.internalWeight || 1) * travelerCount;
            console.log(`Paper visa weight for ${p.country}: ${weight} (${p.internalWeight || 1} × ${travelerCount} travelers)`);
            return sum + weight;
        }, 0);

        const accessibilityScore = 
            (noVisaWeight * 1000) + 
            (visaOnArrivalWeight * 100) + 
            (eVisaWeight * 10) + 
            (paperVisaWeight * 1);

        if (noVisaCount + visaOnArrivalCount + eVisaCount + paperVisaCount > 0) {
            return {
                country: country.country,
                noVisa: noVisa.map(p => ({
                    country: p.country,
                    weight: (p.internalWeight || 1) * (travelerCounts[p.id] || 1)
                })),
                visaOnArrival: visaOnArrival.map(p => ({
                    country: p.country,
                    weight: (p.internalWeight || 1) * (travelerCounts[p.id] || 1)
                })),
                eVisa: eVisa.map(p => ({
                    country: p.country,
                    weight: (p.internalWeight || 1) * (travelerCounts[p.id] || 1)
                })),
                paperVisa: paperVisa.map(p => ({
                    country: p.country,
                    weight: (p.internalWeight || 1) * (travelerCounts[p.id] || 1)
                })),
                totalPassports: selectedPassports.length,
                noVisaCount,
                visaOnArrivalCount,
                eVisaCount,
                paperVisaCount,
                noVisaWeight,
                visaOnArrivalWeight,
                eVisaWeight,
                paperVisaWeight,
                accessibilityScore
            };
        }
        return null;
    }).filter(Boolean);

    console.log('Final comparison results:', results);
    return results.sort((a, b) => b.accessibilityScore - a.accessibilityScore);
};