import React, { useState, useEffect } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { PrimaryButton } from '../../../utils/budget-tool-specific';
import { GetDB } from '../../../utils/database/firebase-budget-tool';
import { collection, setDoc, addDoc, updateDoc, deleteDoc, doc, onSnapshot, getDocs } from 'firebase/firestore';
import { Container, ActionButtonContainer, SeedButton, CardsGrid } from '../passport/commonComponents';
import CountryCard from '../passport/countryCard';
import CountryDetails from './CountryDetails';
import { countries as countriesData } from './countries';
import styled from 'styled-components';
import andorraData from '../passport/countrySeedData/andorra.json';
import albaniaData from '../passport/countrySeedData/albania.json';
import algeriaData from '../passport/countrySeedData/algeria.json';
import angolaData from '../passport/countrySeedData/angola.json';
import antiguaData from '../passport/countrySeedData/antigua-and-barbuda.json';
import argentinaData from '../passport/countrySeedData/argentina.json';
import armeniaData from '../passport/countrySeedData/armenia.json';
import australiaData from '../passport/countrySeedData/australia.json';
import austriaData from '../passport/countrySeedData/austria.json';
import azerbaijanData from '../passport/countrySeedData/azerbaijan.json';
import bahamasData from '../passport/countrySeedData/bahamas.json';
import barbadosData from '../passport/countrySeedData/barbados.json';
import belarusData from '../passport/countrySeedData/belarus.json';
import belgiumData from '../passport/countrySeedData/belgium.json';
import belizeData from '../passport/countrySeedData/belize.json';
import boliviaData from '../passport/countrySeedData/bolivia.json';
import cyprusData from '../passport/countrySeedData/cyprus.json';
import democraticRepublicOfTheCongoData from '../passport/countrySeedData/democratic-republic-of-the-congo.json';
import dominicaData from '../passport/countrySeedData/dominica.json';
import grenadaData from '../passport/countrySeedData/grenada.json';
import guineaBissauData from '../passport/countrySeedData/guinea-bissau.json';
import haitiData from '../passport/countrySeedData/haiti.json';
import israelData from '../passport/countrySeedData/israel.json';
import jamaicaData from '../passport/countrySeedData/jamaica.json';
import jordanData from '../passport/countrySeedData/jordan.json';
import kiribatiData from '../passport/countrySeedData/kiribati.json';
import liechtensteinData from '../passport/countrySeedData/liechtenstein.json';
import malawiData from '../passport/countrySeedData/malawi.json';
import nauruData from '../passport/countrySeedData/nauru.json';
import nicaraguaData from '../passport/countrySeedData/nicaragua.json';
import timorLesteData from '../passport/countrySeedData/timor-leste.json';
import tuvaluData from '../passport/countrySeedData/tuvalu.json';
import ugandaData from '../passport/countrySeedData/uganda.json';
import ukraineData from '../passport/countrySeedData/ukraine.json';
import unitedStatesData from '../passport/countrySeedData/united-states.json';
import uruguayData from '../passport/countrySeedData/uruguay.json';
import uzbekistanData from '../passport/countrySeedData/uzbekistan.json';
import vanuatuData from '../passport/countrySeedData/vanuatu.json';
import vaticanCityData from '../passport/countrySeedData/vatican-city.json';
import venezuelaData from '../passport/countrySeedData/venezuela.json';
import vietnamData from '../passport/countrySeedData/vietnam.json';
import zambiaData from '../passport/countrySeedData/zambia.json';
import zimbabweData from '../passport/countrySeedData/zimbabwe.json';
import turkmenistanData from '../passport/countrySeedData/turkmenistan.json';
import turkeyData from '../passport/countrySeedData/turkey.json';
import tunisiaData from '../passport/countrySeedData/tunisia.json';
import tongaData from '../passport/countrySeedData/tonga.json';
import togoData from '../passport/countrySeedData/togo.json';
import thailandData from '../passport/countrySeedData/thailand.json';
import tanzaniaData from '../passport/countrySeedData/tanzania.json';
import tajikistanData from '../passport/countrySeedData/tajikistan.json';
import taiwanData from '../passport/countrySeedData/taiwan.json';
import syriaData from '../passport/countrySeedData/syria.json';
import switzerlandData from '../passport/countrySeedData/switzerland.json';
import swedenData from '../passport/countrySeedData/sweden.json';
import surinameData from '../passport/countrySeedData/suriname.json';
import sudanData from '../passport/countrySeedData/sudan.json';
import sriLankaData from '../passport/countrySeedData/sri-lanka.json';
import spainData from '../passport/countrySeedData/spain.json';
import southSudanData from '../passport/countrySeedData/south-sudan.json';
import somaliaData from '../passport/countrySeedData/somalia.json';
import solomonIslandsData from '../passport/countrySeedData/solomon-islands.json';
import sloveniaData from '../passport/countrySeedData/slovenia.json';
import slovakiaData from '../passport/countrySeedData/slovakia.json';
import singaporeData from '../passport/countrySeedData/singapore.json';
import seychellesData from '../passport/countrySeedData/seychelles.json';
import serbiaData from '../passport/countrySeedData/serbia.json';
import senegalData from '../passport/countrySeedData/senegal.json';
import saoTomeAndPrincipeData from '../passport/countrySeedData/sao-tome-and-principe.json';
import sanMarinoData from '../passport/countrySeedData/san-marino.json';
import samoaData from '../passport/countrySeedData/samoa.json';
import saintVincentAndTheGrenadinesData from '../passport/countrySeedData/saint-vincent-and-the-grenadines.json';
import saintLuciaData from '../passport/countrySeedData/saint-lucia.json';
import saintKittsAndNevisData from '../passport/countrySeedData/saint-kitts-and-nevis.json';
import rwandaData from '../passport/countrySeedData/rwanda.json';
import russiaData from '../passport/countrySeedData/russia.json';
import romaniaData from '../passport/countrySeedData/romania.json';
import republicOfTheCongoData from '../passport/countrySeedData/republic-of-the-congo.json';
import qatarData from '../passport/countrySeedData/qatar.json';
import portugalData from '../passport/countrySeedData/portugal.json';
import polandData from '../passport/countrySeedData/poland.json';
import philippinesData from '../passport/countrySeedData/philippines.json';
import peruData from '../passport/countrySeedData/peru.json';
import paraguayData from '../passport/countrySeedData/paraguay.json';
import papuaNewGuineaData from '../passport/countrySeedData/papua-new-guinea.json';
import panamaData from '../passport/countrySeedData/panama.json';
import palauData from '../passport/countrySeedData/palau.json';
import pakistanData from '../passport/countrySeedData/pakistan.json';
import omanData from '../passport/countrySeedData/oman.json';
import norwayData from '../passport/countrySeedData/norway.json';
import northKoreaData from '../passport/countrySeedData/north-korea.json';
import nigeriaData from '../passport/countrySeedData/nigeria.json';
import nigerData from '../passport/countrySeedData/niger.json';
import namibiaData from '../passport/countrySeedData/namibia.json';
import myanmarData from '../passport/countrySeedData/myanmar.json';
import mozambiqueData from '../passport/countrySeedData/mozambique.json';
import moroccoData from '../passport/countrySeedData/morocco.json';
import montenegroData from '../passport/countrySeedData/montenegro.json';
import mongoliaData from '../passport/countrySeedData/mongolia.json';
import monacoData from '../passport/countrySeedData/monaco.json';
import moldovaData from '../passport/countrySeedData/moldova.json';
import micronesiaData from '../passport/countrySeedData/micronesia.json';
import mexicoData from '../passport/countrySeedData/mexico.json';
import mauritiusData from '../passport/countrySeedData/mauritius.json';
import mauritaniaData from '../passport/countrySeedData/mauritania.json';
import marshallIslandsData from '../passport/countrySeedData/marshall-islands.json';
import maltaData from '../passport/countrySeedData/malta.json';
import maliData from '../passport/countrySeedData/mali.json';
import maldivesData from '../passport/countrySeedData/maldives.json';
import malaysiaData from '../passport/countrySeedData/malaysia.json';
import madagascarData from '../passport/countrySeedData/madagascar.json';
import macedoniaData from '../passport/countrySeedData/macedonia.json';
import luxembourgData from '../passport/countrySeedData/luxembourg.json';
import lithuaniaData from '../passport/countrySeedData/lithuania.json';
import libyaData from '../passport/countrySeedData/libya.json';
import liberiaData from '../passport/countrySeedData/liberia.json';
import lesothoData from '../passport/countrySeedData/lesotho.json';
import lebanonData from '../passport/countrySeedData/lebanon.json';
import latviaData from '../passport/countrySeedData/latvia.json';
import laosData from '../passport/countrySeedData/laos.json';
import kyrgyzstanData from '../passport/countrySeedData/kyrgyzstan.json';
import kuwaitData from '../passport/countrySeedData/kuwait.json';
import kosovoData from '../passport/countrySeedData/kosovo.json';
import netherlandsData from '../passport/countrySeedData/netherlands.json';
import nepalData from '../passport/countrySeedData/nepal.json';

const SettingsCountry = () => {
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [passports, setPassports] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const db = GetDB();

    useEffect(() => {
        const unsubscribeCountries = onSnapshot(collection(db, 'Countries'), (snapshot) => {
            const countryData = [];
            snapshot.forEach((doc) => {
                countryData.push({ id: doc.id, ...doc.data() });
            });
            setCountries(countryData);
            
            // If there's a selected country, update it with the new data
            if (selectedCountry) {
                const updatedSelectedCountry = countryData.find(country => country.id === selectedCountry.id);
                if (updatedSelectedCountry) {
                    setSelectedCountry(updatedSelectedCountry);
                }
            }
        });

        const unsubscribePassports = onSnapshot(collection(db, 'Passports'), (snapshot) => {
            const passportData = [];
            snapshot.forEach((doc) => {
                passportData.push({
                    value: doc.id,
                    label: doc.data().country
                });
            });
            setPassports(passportData);
        });

        return () => {
            unsubscribeCountries();
            unsubscribePassports();
        };
    }, [selectedCountry]);

    const handleAddCountry = async () => {
        const newCountry = {
            country: 'New Country',
            noVisaRequired: [],
            visaOnArrival: [],
            eVisaRequired: [],
            paperVisaRequired: [],
            restrictedPassports: [],
            information: '',
            notes: '',
            internalWeight: 1
        };

        try {
            await addDoc(collection(db, 'Countries'), newCountry);
        } catch (error) {
            console.error("Error adding country:", error);
        }
    };

    const importCountryData = async () => {
        try {
            // Get all passports for mapping
            const passportsSnapshot = await getDocs(collection(db, 'Passports'));
            const passportsData = [];
            passportsSnapshot.forEach((doc) => {
                passportsData.push({ id: doc.id, ...doc.data() });
            });

            // Create mapping of country names to passport IDs
            const passportMap = new Map(
                passportsData.map(passport => [passport.country.toLowerCase(), passport.id])
            );

            // Helper function to convert country names to passport IDs
            const convertToPassportIds = (countryNames) => {
                return countryNames
                    .map(name => passportMap.get(name.toLowerCase()))
                    .filter(id => id !== undefined);
            };

            // Map of country codes to their data and ISO codes
            const countryDataMap = {
                'andorra': { data: andorraData, code: 'AD' },
                'albania': { data: albaniaData, code: 'AL' },
                'algeria': { data: algeriaData, code: 'DZ' },
                'angola': { data: angolaData, code: 'AO' },
                'antigua-and-barbuda': { data: antiguaData, code: 'AG' },
                'argentina': { data: argentinaData, code: 'AR' },
                'armenia': { data: armeniaData, code: 'AM' },
                'australia': { data: australiaData, code: 'AU' },
                'austria': { data: austriaData, code: 'AT' },
                'azerbaijan': { data: azerbaijanData, code: 'AZ' }
            };

            console.log('Starting import of countries:', Object.keys(countryDataMap));

            for (const [countryKey, { data: countryData, code: countryCode }] of Object.entries(countryDataMap)) {
                try {
                    console.log(`Importing ${countryKey} with code ${countryCode}...`);
                    console.log(`Data for ${countryKey}:`, countryData);

                    // Transform the data
                    const transformedData = {
                        country: countryData.countryName || countryKey.split('-').map(word => 
                            word.charAt(0).toUpperCase() + word.slice(1)
                        ).join(' '),
                        region: countryData.region,
                        information: countryData.additional_info || '',
                        noVisaRequired: convertToPassportIds(countryData.visa_free || []),
                        visaOnArrival: convertToPassportIds(countryData.visa_on_arrival || []),
                        eVisaRequired: convertToPassportIds(countryData.e_visa || []),
                        paperVisaRequired: convertToPassportIds(countryData.visa_required || []),
                        restrictedPassports: [],
                        internalWeight: 1
                    };

                    // Save to Firestore using the ISO 2-letter code
                    const countryRef = doc(db, 'Countries', countryCode);
                    await setDoc(countryRef, transformedData);
                    console.log(`Successfully imported ${countryKey} with code ${countryCode}`);
                } catch (error) {
                    console.error(`Error importing ${countryKey}:`, error);
                }
            }

            console.log('Import completed');
        } catch (error) {
            console.error("Error importing country data:", error);
        }
    };

    const importNext20Countries = async () => {
        try {
            // Get all passports for mapping
            const passportsSnapshot = await getDocs(collection(db, 'Passports'));
            const passportsData = [];
            passportsSnapshot.forEach((doc) => {
                passportsData.push({ id: doc.id, ...doc.data() });
            });

            // Create mapping of country names to passport IDs
            const passportMap = new Map(
                passportsData.map(passport => [passport.country.toLowerCase(), passport.id])
            );

            // Helper function to convert country names to passport IDs
            const convertToPassportIds = (countryNames) => {
                return countryNames
                    .map(name => passportMap.get(name.toLowerCase()))
                    .filter(id => id !== undefined);
            };

            // Map of country codes to their data and ISO codes
            const countryDataMap = {
                'bahamas': { data: bahamasData, code: 'BS' },
                'barbados': { data: barbadosData, code: 'BB' },
                'belarus': { data: belarusData, code: 'BY' },
                'belgium': { data: belgiumData, code: 'BE' },
                'belize': { data: belizeData, code: 'BZ' },
                'bolivia': { data: boliviaData, code: 'BO' },
                'cyprus': { data: cyprusData, code: 'CY' },
                'democratic-republic-of-the-congo': { data: democraticRepublicOfTheCongoData, code: 'CD' },
                'dominica': { data: dominicaData, code: 'DM' },
                'grenada': { data: grenadaData, code: 'GD' },
                'guinea-bissau': { data: guineaBissauData, code: 'GW' },
                'haiti': { data: haitiData, code: 'HT' },
                'israel': { data: israelData, code: 'IL' },
                'jamaica': { data: jamaicaData, code: 'JM' },
                'jordan': { data: jordanData, code: 'JO' },
                'kiribati': { data: kiribatiData, code: 'KI' },
                'liechtenstein': { data: liechtensteinData, code: 'LI' },
                'malawi': { data: malawiData, code: 'MW' },
                'nauru': { data: nauruData, code: 'NR' },
                'nicaragua': { data: nicaraguaData, code: 'NI' },
                'timor-leste': { data: timorLesteData, code: 'TL' },
                'tuvalu': { data: tuvaluData, code: 'TV' }
            };

            console.log('Starting import of next 20 countries:', Object.keys(countryDataMap));

            for (const [countryKey, { data: countryData, code: countryCode }] of Object.entries(countryDataMap)) {
                try {
                    console.log(`Importing ${countryKey} with code ${countryCode}...`);
                    console.log(`Data for ${countryKey}:`, countryData);

                    // Transform the data
                    const transformedData = {
                        country: countryData.countryName || countryKey.split('-').map(word => 
                            word.charAt(0).toUpperCase() + word.slice(1)
                        ).join(' '),
                        region: countryData.region,
                        information: countryData.additional_info || '',
                        noVisaRequired: convertToPassportIds(countryData.visa_free || []),
                        visaOnArrival: convertToPassportIds(countryData.visa_on_arrival || []),
                        eVisaRequired: convertToPassportIds(countryData.e_visa || []),
                        paperVisaRequired: convertToPassportIds(countryData.visa_required || []),
                        restrictedPassports: [],
                        internalWeight: 1
                    };

                    // Save to Firestore using the ISO 2-letter code
                    const countryRef = doc(db, 'Countries', countryCode);
                    await setDoc(countryRef, transformedData);
                    console.log(`Successfully imported ${countryKey} with code ${countryCode}`);
                } catch (error) {
                    console.error(`Error importing ${countryKey}:`, error);
                }
            }

            console.log('Import completed');
        } catch (error) {
            console.error("Error importing country data:", error);
        }
    };

    const importNext50Countries = async () => {
        try {
            // Get all passports for mapping
            const passportsSnapshot = await getDocs(collection(db, 'Passports'));
            const passportsData = [];
            passportsSnapshot.forEach((doc) => {
                passportsData.push({ id: doc.id, ...doc.data() });
            });

            // Create mapping of country names to passport IDs
            const passportMap = new Map(
                passportsData.map(passport => [passport.country.toLowerCase(), passport.id])
            );

            // Helper function to convert country names to passport IDs
            const convertToPassportIds = (countryNames) => {
                return countryNames
                    .map(name => passportMap.get(name.toLowerCase()))
                    .filter(id => id !== undefined);
            };

            // Map of country codes to their data and ISO codes
            const countryDataMap = {
                'uganda': { data: ugandaData, code: 'UG' },
                'ukraine': { data: ukraineData, code: 'UA' },
                'united-states': { data: unitedStatesData, code: 'US' },
                'uruguay': { data: uruguayData, code: 'UY' },
                'uzbekistan': { data: uzbekistanData, code: 'UZ' },
                'vanuatu': { data: vanuatuData, code: 'VU' },
                'vatican-city': { data: vaticanCityData, code: 'VA' },
                'venezuela': { data: venezuelaData, code: 'VE' },
                'vietnam': { data: vietnamData, code: 'VN' },
                'zambia': { data: zambiaData, code: 'ZM' },
                'zimbabwe': { data: zimbabweData, code: 'ZW' }
            };

            console.log('Starting import of next 50 countries:', Object.keys(countryDataMap));

            for (const [countryKey, { data: countryData, code: countryCode }] of Object.entries(countryDataMap)) {
                try {
                    console.log(`Importing ${countryKey} with code ${countryCode}...`);
                    console.log(`Data for ${countryKey}:`, countryData);

                    // Transform the data
                    const transformedData = {
                        country: countryData.countryName || countryKey.split('-').map(word => 
                            word.charAt(0).toUpperCase() + word.slice(1)
                        ).join(' '),
                        region: countryData.region,
                        information: countryData.additional_info || '',
                        noVisaRequired: convertToPassportIds(countryData.visa_free || []),
                        visaOnArrival: convertToPassportIds(countryData.visa_on_arrival || []),
                        eVisaRequired: convertToPassportIds(countryData.e_visa || []),
                        paperVisaRequired: convertToPassportIds(countryData.visa_required || []),
                        restrictedPassports: [],
                        internalWeight: 1
                    };

                    // Save to Firestore using the ISO 2-letter code
                    const countryRef = doc(db, 'Countries', countryCode);
                    await setDoc(countryRef, transformedData);
                    console.log(`Successfully imported ${countryKey} with code ${countryCode}`);
                } catch (error) {
                    console.error(`Error importing ${countryKey}:`, error);
                }
            }

            console.log('Import completed');
        } catch (error) {
            console.error("Error importing country data:", error);
        }
    };

    const importAllCountries = async () => {
        try {
            // Get all passports for mapping
            const passportsSnapshot = await getDocs(collection(db, 'Passports'));
            const passportsData = [];
            passportsSnapshot.forEach((doc) => {
                passportsData.push({ id: doc.id, ...doc.data() });
            });

            // Create mapping of country names to passport IDs
            const passportMap = new Map(
                passportsData.map(passport => [passport.country.toLowerCase(), passport.id])
            );

            // Helper function to convert country names to passport IDs
            const convertToPassportIds = (countryNames) => {
                return countryNames
                    .map(name => passportMap.get(name.toLowerCase()))
                    .filter(id => id !== undefined);
            };

            // Map of country codes to their data and ISO codes
            const countryDataMap = {
                'zambia': { data: zambiaData, code: 'ZM' },
                'zimbabwe': { data: zimbabweData, code: 'ZW' },
                'vietnam': { data: vietnamData, code: 'VN' },
                'venezuela': { data: venezuelaData, code: 'VE' },
                'vatican-city': { data: vaticanCityData, code: 'VA' },
                'vanuatu': { data: vanuatuData, code: 'VU' },
                'uzbekistan': { data: uzbekistanData, code: 'UZ' },
                'uruguay': { data: uruguayData, code: 'UY' },
                'united-states': { data: unitedStatesData, code: 'US' },
                'ukraine': { data: ukraineData, code: 'UA' },
                'uganda': { data: ugandaData, code: 'UG' },
                'tuvalu': { data: tuvaluData, code: 'TV' },
                'turkmenistan': { data: turkmenistanData, code: 'TM' },
                'turkey': { data: turkeyData, code: 'TR' },
                'tunisia': { data: tunisiaData, code: 'TN' },
                'tonga': { data: tongaData, code: 'TO' },
                'togo': { data: togoData, code: 'TG' },
                'timor-leste': { data: timorLesteData, code: 'TL' },
                'thailand': { data: thailandData, code: 'TH' },
                'tanzania': { data: tanzaniaData, code: 'TZ' },
                'tajikistan': { data: tajikistanData, code: 'TJ' },
                'taiwan': { data: taiwanData, code: 'TW' },
                'syria': { data: syriaData, code: 'SY' },
                'switzerland': { data: switzerlandData, code: 'CH' },
                'sweden': { data: swedenData, code: 'SE' },
                'suriname': { data: surinameData, code: 'SR' },
                'sudan': { data: sudanData, code: 'SD' },
                'sri-lanka': { data: sriLankaData, code: 'LK' },
                'spain': { data: spainData, code: 'ES' },
                'south-sudan': { data: southSudanData, code: 'SS' },
                'somalia': { data: somaliaData, code: 'SO' },
                'solomon-islands': { data: solomonIslandsData, code: 'SB' },
                'slovenia': { data: sloveniaData, code: 'SI' },
                'slovakia': { data: slovakiaData, code: 'SK' },
                'singapore': { data: singaporeData, code: 'SG' },
                'seychelles': { data: seychellesData, code: 'SC' },
                'serbia': { data: serbiaData, code: 'RS' },
                'senegal': { data: senegalData, code: 'SN' },
                'sao-tome-and-principe': { data: saoTomeAndPrincipeData, code: 'ST' },
                'san-marino': { data: sanMarinoData, code: 'SM' },
                'samoa': { data: samoaData, code: 'WS' },
                'saint-vincent-and-the-grenadines': { data: saintVincentAndTheGrenadinesData, code: 'VC' },
                'saint-lucia': { data: saintLuciaData, code: 'LC' },
                'saint-kitts-and-nevis': { data: saintKittsAndNevisData, code: 'KN' },
                'rwanda': { data: rwandaData, code: 'RW' },
                'russia': { data: russiaData, code: 'RU' },
                'romania': { data: romaniaData, code: 'RO' },
                'republic-of-the-congo': { data: republicOfTheCongoData, code: 'CG' },
                'qatar': { data: qatarData, code: 'QA' },
                'portugal': { data: portugalData, code: 'PT' },
                'poland': { data: polandData, code: 'PL' },
                'philippines': { data: philippinesData, code: 'PH' },
                'peru': { data: peruData, code: 'PE' },
                'paraguay': { data: paraguayData, code: 'PY' },
                'papua-new-guinea': { data: papuaNewGuineaData, code: 'PG' },
                'panama': { data: panamaData, code: 'PA' },
                'palau': { data: palauData, code: 'PW' },
                'pakistan': { data: pakistanData, code: 'PK' },
                'oman': { data: omanData, code: 'OM' },
                'norway': { data: norwayData, code: 'NO' },
                'north-korea': { data: northKoreaData, code: 'KP' },
                'nigeria': { data: nigeriaData, code: 'NG' },
                'niger': { data: nigerData, code: 'NE' },
                'nicaragua': { data: nicaraguaData, code: 'NI' },
                'netherlands': { data: netherlandsData, code: 'NL' },
                'nepal': { data: nepalData, code: 'NP' },
                'nauru': { data: nauruData, code: 'NR' },
                'namibia': { data: namibiaData, code: 'NA' },
                'myanmar': { data: myanmarData, code: 'MM' },
                'mozambique': { data: mozambiqueData, code: 'MZ' },
                'morocco': { data: moroccoData, code: 'MA' },
                'montenegro': { data: montenegroData, code: 'ME' },
                'mongolia': { data: mongoliaData, code: 'MN' },
                'monaco': { data: monacoData, code: 'MC' },
                'moldova': { data: moldovaData, code: 'MD' },
                'micronesia': { data: micronesiaData, code: 'FM' },
                'mexico': { data: mexicoData, code: 'MX' },
                'mauritius': { data: mauritiusData, code: 'MU' },
                'mauritania': { data: mauritaniaData, code: 'MR' },
                'marshall-islands': { data: marshallIslandsData, code: 'MH' },
                'malta': { data: maltaData, code: 'MT' },
                'mali': { data: maliData, code: 'ML' },
                'maldives': { data: maldivesData, code: 'MV' },
                'malaysia': { data: malaysiaData, code: 'MY' },
                'malawi': { data: malawiData, code: 'MW' },
                'madagascar': { data: madagascarData, code: 'MG' },
                'macedonia': { data: macedoniaData, code: 'MK' },
                'luxembourg': { data: luxembourgData, code: 'LU' },
                'lithuania': { data: lithuaniaData, code: 'LT' },
                'liechtenstein': { data: liechtensteinData, code: 'LI' },
                'libya': { data: libyaData, code: 'LY' },
                'liberia': { data: liberiaData, code: 'LR' },
                'lesotho': { data: lesothoData, code: 'LS' },
                'lebanon': { data: lebanonData, code: 'LB' },
                'latvia': { data: latviaData, code: 'LV' },
                'laos': { data: laosData, code: 'LA' },
                'kyrgyzstan': { data: kyrgyzstanData, code: 'KG' },
                'kuwait': { data: kuwaitData, code: 'KW' },
                'kosovo': { data: kosovoData, code: 'XK' },
                'kiribati': { data: kiribatiData, code: 'KI' }
            };

            console.log('Starting import of all countries:', Object.keys(countryDataMap));

            for (const [countryKey, { data: countryData, code: countryCode }] of Object.entries(countryDataMap)) {
                try {
                    console.log(`Importing ${countryKey} with code ${countryCode}...`);
                    console.log(`Data for ${countryKey}:`, countryData);

                    // Transform the data
                    const transformedData = {
                        country: countryData.countryName || countryKey.split('-').map(word => 
                            word.charAt(0).toUpperCase() + word.slice(1)
                        ).join(' '),
                        region: countryData.region,
                        information: countryData.additional_info || '',
                        noVisaRequired: convertToPassportIds(countryData.visa_free || []),
                        visaOnArrival: convertToPassportIds(countryData.visa_on_arrival || []),
                        eVisaRequired: convertToPassportIds(countryData.e_visa || []),
                        paperVisaRequired: convertToPassportIds(countryData.visa_required || []),
                        restrictedPassports: [],
                        internalWeight: 1
                    };

                    // Save to Firestore using the ISO 2-letter code
                    const countryRef = doc(db, 'Countries', countryCode);
                    await setDoc(countryRef, transformedData);
                    console.log(`Successfully imported ${countryKey} with code ${countryCode}`);
                } catch (error) {
                    console.error(`Error importing ${countryKey}:`, error);
                }
            }

            console.log('Import completed');
        } catch (error) {
            console.error("Error importing country data:", error);
        }
    };

    const handleCountrySelect = (country) => {
        setSelectedCountry(country);
    };

    const handleClose = () => {
        setSelectedCountry(null);
    };

    const filteredCountries = countries.filter(country =>
        country?.country?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Container fluid>
            <Row className="mb-4">
                <Col>
                    <h4>Country Database</h4>
                </Col>
                <Col className="text-end">
                    <ActionButtonContainer>
                        <PrimaryButton onClick={handleAddCountry}>
                            Add Country
                        </PrimaryButton>
                        <SeedButton onClick={importCountryData}>
                            Import First 10 Countries
                        </SeedButton>
                        <SeedButton onClick={importNext20Countries}>
                            Import Next 20 Countries
                        </SeedButton>
                        <SeedButton onClick={importNext50Countries}>
                            Import Next 50 Countries
                        </SeedButton>
                        <SeedButton onClick={importAllCountries}>
                            Import All Countries
                        </SeedButton>
                    </ActionButtonContainer>
                </Col>
            </Row>

            <SearchContainer>
                <SearchInput 
                    type="text"
                    placeholder="Search countries..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </SearchContainer>

            <ContentRow>
                <CountriesList>
                    <CardsGrid>
                        {filteredCountries.map((country) => (
                            <CountryCard
                                key={country.id}
                                country={country}
                                isSelected={selectedCountry?.id === country.id}
                                onSelect={() => handleCountrySelect(country)}
                            />
                        ))}
                    </CardsGrid>
                </CountriesList>

                {selectedCountry && (
                    <CountryDetails
                        country={selectedCountry}
                        passports={passports}
                        onClose={handleClose}
                    />
                )}
            </ContentRow>
        </Container>
    );
};

const ContentRow = styled.div`
    display: flex;
    gap: 20px;
    min-height: calc(100vh - 200px);
`;

const CountriesList = styled.div`
    flex: 1;
    padding-right: 10px;
`;

const SearchContainer = styled.div`
    margin-bottom: 20px;
`;

const SearchInput = styled(Input)`
    max-width: 300px;
    border-radius: 4px;
    padding: 8px 12px;
    
    &:focus {
        border-color: ${props => props.theme.primaryAccent};
        box-shadow: 0 0 0 1px ${props => props.theme.primaryAccent};
        outline: none;
    }
`;

export default SettingsCountry;