import React from 'react';
import styled from 'styled-components';
import PageTemplate from '../page-template';
import { HeaderTitle, Spacer } from '../../../utils/budget-tool-specific';
import PassportList from './passport-list';

const DisclaimerText = styled.div`
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 15px;
    margin: 15px 0;
    font-size: 14px;
    color: #666;
    line-height: 1.5;
`;

const PassportComponent = (props) => {
    return (
        <React.Fragment>
            <Spacer height="60px" id="height-spacer"/>
            <HeaderTitle>
                Passport Guides
            </HeaderTitle>
            <DisclaimerText>
                Information is provided as guidance only and accurate at the time of publishing. Always check government websites and airline materials before booking and traveling.
            </DisclaimerText>
            <PassportList/>
        </React.Fragment>
    );
};

const Passport = (props) => {
    if (props.collapsed) {
        return <PassportComponent/>;
    } else {
        return <PageTemplate component={<PassportComponent/>} client={props.client}/>;
    }
};

export default Passport;