import React, { useState, useEffect }  from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./header";
import BudgetHome from './budget-home/budget-home';
import BudgetTool from './budget-tool/budget-tool';
import Experiences from './experiences/experiences';
import ExperiencesEdit from './experiences/experience-form/experiences-edit';
import Flights from "./flights/flights";
import FlightsEdit from "./flights/flights-edit";
import Settings from "./settings/settings";
import Assistant from "./assistant/assistant";
import Passport from "./passport/passport";
import PassportEdit from "./passport/passport-edit";

const BudgetApp = (props) => {

    console.log("BudgetApp props", props);

    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [collapsed, setCollapsed] = useState(false);

    let client = false;
    if (window.location.href.indexOf("/budget-app/experiences/") > -1) {
        client = true;
        if (window.location.href.indexOf("edit") > -1) {
            client = false;
        }
    }
    if (window.location.href.indexOf("/budget-app/flights/") > -1) {
        client = true;
        if (window.location.href.indexOf("edit") > -1) {
            client = false;
        }
    }
    if (window.location.href.indexOf("/budget-app/passport/") > -1) {
        client = true;
        if (window.location.href.indexOf("edit") > -1) {
            client = false;
        }
    }
  
    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
  
    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }

  
    return (
      <Router>
        <Header windowSize={windowSize} collapsed={collapsed} setCollapsed={(x) => setCollapsed(x)}client={client}/>
        <Switch>
            <Route title="Budget" path="/budget-app/budget-demo">
                <BudgetTool collapsed={collapsed}/>     
            </Route>
            <Route title="Experiences" path="/budget-app/experiences/edit/:experience">
                <ExperiencesEdit collapsed={collapsed} client={false}/>
            </Route>
            <Route title="Experiences" path="/budget-app/experiences/:experience">
                <ExperiencesEdit collapsed={collapsed} client={true} setCollapsed={x => setCollapsed(x)} />
            </Route>
            <Route title="Experiences" path="/budget-app/experiences/">
                <Experiences collapsed={collapsed} client={client}/>
            </Route>
            <Route title="Flights" path="/budget-app/flights/edit/:experience">
                <FlightsEdit collapsed={collapsed} client={false}/>     
            </Route>
            <Route title="Flights" path="/budget-app/flights/:experience">
                <FlightsEdit collapsed={collapsed} client={true}/>     
            </Route>
            <Route title="Flights" path="/budget-app/flights">
                <Flights collapsed={collapsed}/>     
            </Route>
            <Route title="Assistant" path="/budget-app/assistant">
                <Assistant collapsed={collapsed}/>     
            </Route>
            <Route title="Settings" path="/budget-app/settings">
                <Settings collapsed={collapsed}/>     
            </Route>
            <Route title="Passports" path="/budget-app/passport/edit/:experience">
                <PassportEdit collapsed={collapsed} client={false}/>     
            </Route>
            <Route title="Passports" path="/budget-app/passport/:experience">
                <PassportEdit collapsed={collapsed} client={true}/>     
            </Route>
            <Route title="Passports" path="/budget-app/passport">
                <Passport collapsed={collapsed}/>     
            </Route>
            <Route title="Home" path="/budget-app/">
                <BudgetHome collapsed={collapsed} client={client}/>
            </Route>
        </Switch>
        
      </Router>
    );

}

export default BudgetApp;