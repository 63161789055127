// PassportGrid.jsx
import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import { Colors, PrimaryButton } from '../../../utils/budget-tool-specific';
import styled from 'styled-components';
import { GetDB } from '../../../utils/database/firebase-budget-tool';
import { collection, onSnapshot, doc } from 'firebase/firestore';
import PassportComparison from './passportComparison';
import { comparePassports } from './comparePassports';

const PassportGrid = ({ passportData, setPassportData, client }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [comparisonResults, setComparisonResults] = useState([]);
    const [countries, setCountries] = useState([]);
    const [selectedDestination, setSelectedDestination] = useState(null);
    const [visaWeights, setVisaWeights] = useState({
        noVisa: 1000,
        visaOnArrival: 100,
        eVisa: 10,
        paperVisa: 1,
        restricted: 0
    });
    const db = GetDB();

    useEffect(() => {
        // Get countries data
        const unsubscribeCountries = onSnapshot(collection(db, 'Countries'), (snapshot) => {
            const countryData = [];
            snapshot.forEach((docSnapshot) => {
                countryData.push({ id: docSnapshot.id, ...docSnapshot.data() });
            });
            setCountries(countryData);
        });

        // Get visa weights
        const unsubscribeWeights = onSnapshot(doc(db, 'Settings', 'visaWeights'), (docSnapshot) => {
            if (docSnapshot.exists()) {
                setVisaWeights(docSnapshot.data());
            }
        });

        return () => {
            unsubscribeCountries();
            unsubscribeWeights();
        };
    }, []);

    const handleCompare = () => {
        if (!passportData?.selectedCountries) return;
        const results = comparePassports(
            countries, 
            passportData.selectedCountries, 
            countries,
            passportData.travelerCounts || {}
        );
        setComparisonResults(results);
        setShowResults(true);
    };

    const handleDestinationClick = (country) => {
        setSelectedDestination(country);
    };

    const getVisaStatus = (country, passportId) => {
        if (country.noVisaRequired?.includes(passportId)) return 'No Visa Required';
        if (country.visaOnArrival?.includes(passportId)) return 'Visa On Arrival';
        if (country.eVisaRequired?.includes(passportId)) return 'eVisa/ETA Required';
        if (country.paperVisaRequired?.includes(passportId)) return 'Paper Visa Required';
        if (country.restrictedPassports?.includes(passportId)) return 'Restricted';
        return 'Not Listed';
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'No Visa Required': return '#4CAF50';
            case 'Visa On Arrival': return '#FFC107';
            case 'eVisa/ETA Required': return '#FF9800';
            case 'Paper Visa Required': return '#F44336';
            case 'Restricted': return '#9E9E9E';
            default: return '#757575';
        }
    };

    const filteredCountries = countries.filter(country => 
        country.country.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const selectedCountries = countries.filter(country => 
        passportData?.selectedCountries?.includes(country.id)
    );
    const unselectedCountries = filteredCountries.filter(country => 
        !passportData?.selectedCountries?.includes(country.id)
    );

    const handleTravelerCountChange = (countryId, count) => {
        if (!passportData) return;
        
        if (count === '') {
            setPassportData(prev => ({
                ...prev,
                travelerCounts: {
                    ...prev.travelerCounts,
                    [countryId]: ''
                }
            }));
            return;
        }
        
        const numCount = parseInt(count);
        if (isNaN(numCount) || numCount < 0) return;
        
        setPassportData(prev => ({
            ...prev,
            travelerCounts: {
                ...prev.travelerCounts,
                [countryId]: numCount
            }
        }));
    };

    const handleTravelerBlur = (countryId) => {
        if (!passportData) return;
        const currentValue = passportData.travelerCounts?.[countryId];
        if (currentValue === '') {
            setPassportData(prev => ({
                ...prev,
                travelerCounts: {
                    ...prev.travelerCounts,
                    [countryId]: 1
                }
            }));
        }
    };

    const handleRemoveCountry = (e, countryId) => {
        if (!passportData) return;
        e.stopPropagation();
        const newSelectedCountries = (passportData.selectedCountries || []).filter(id => id !== countryId);
        const newTravelerCounts = { ...passportData.travelerCounts };
        delete newTravelerCounts[countryId];
        
        setPassportData(prev => ({
            ...prev,
            selectedCountries: newSelectedCountries,
            travelerCounts: newTravelerCounts
        }));
    };

    const handleCountrySelect = (countryId) => {
        if (!passportData) return;
        const newSelectedCountries = [...(passportData.selectedCountries || []), countryId];
        setPassportData(prev => ({
            ...prev,
            selectedCountries: newSelectedCountries,
            travelerCounts: {
                ...prev.travelerCounts,
                [countryId]: 1 // Default to 1 traveler for new selections
            }
        }));
    };

    if (!passportData) {
        return <div>Loading...</div>;
    }

    return (
        <Container>
            <TopSection>
                {!client && (
                    <SearchInput 
                        type="text"
                        placeholder="Search passports..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                )}
                <PrimaryButton 
                    onClick={handleCompare}
                    disabled={!passportData.selectedCountries?.length}
                    style={client ? { marginLeft: 'auto' } : undefined}
                >
                    Compare Selected Passports
                </PrimaryButton>
            </TopSection>

            {showResults && (
                <PassportComparison 
                    comparisonResults={comparisonResults}
                    onClose={() => setShowResults(false)}
                    visaWeights={visaWeights}
                    selectedPassports={passportData.selectedCountries}
                    countries={countries}
                />
            )}

            <GridContainer>
                {!client && (
                    <GridColumn>
                        <ColumnTitle>Available Passports</ColumnTitle>
                        <GridContent>
                            {unselectedCountries.map(country => (
                                <PassportCard 
                                    key={country.id}
                                    onClick={() => handleCountrySelect(country.id)}
                                    isSelected={false}
                                >
                                    <span>{country.country}</span>
                                </PassportCard>
                            ))}
                        </GridContent>
                    </GridColumn>
                )}
                <GridColumn>
                    <ColumnTitle>Selected Passports</ColumnTitle>
                    <GridContent>
                        {selectedCountries.map(country => (
                            <PassportCard 
                                key={country.id}
                                isSelected={true}
                            >
                                <span>{country.country}</span>
                                {!client && (
                                    <RemoveButton onClick={(e) => handleRemoveCountry(e, country.id)}>×</RemoveButton>
                                )}
                                <InputContainer>
                                    <InputLabel>Travelers:</InputLabel>
                                    <TravelerInput
                                        type="number"
                                        min="0"
                                        value={passportData.travelerCounts?.[country.id] ?? 1}
                                        onChange={(e) => handleTravelerCountChange(country.id, e.target.value)}
                                        onBlur={() => handleTravelerBlur(country.id)}
                                        onFocus={(e) => e.target.select()}
                                        onClick={(e) => e.stopPropagation()}
                                        disabled={client}
                                    />
                                </InputContainer>
                            </PassportCard>
                        ))}
                    </GridContent>
                </GridColumn>
            </GridContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
`;

const SearchInput = styled(Input)`
    max-width: 300px;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
`;

const GridColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ColumnTitle = styled.h5`
    color: ${Colors.primaryDark};
    margin: 0;
`;

const GridContent = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    gap: 15px;
    padding: 15px;
`;

// This is for the passport cards in the selection grid, not the detailed passport card component
const PassportCard = styled.div`
    background: ${props => props.isSelected 
        ? Colors.primaryAccent 
        : '#ffffff'};
    color: ${props => props.isSelected ? Colors.primaryDark : Colors.primaryDark};
    padding: 12px;
    border-radius: 6px;
    cursor: ${props => props.isSelected ? 'default' : 'pointer'};
    text-align: center;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: ${props => props.isSelected ? 'column' : 'row'};
    align-items: center;
    justify-content: center;
    min-height: ${props => props.isSelected ? '100px' : '60px'};
    height: ${props => props.isSelected ? '100px' : '60px'};
    position: relative;
    border: 2px solid ${props => props.isSelected ? Colors.primaryAccent : '#d0d0d0'};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
    /* Support for exactly two lines of text with ellipsis */
    & > span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.6;
        word-break: break-word;
        width: 100%;
        margin-bottom: ${props => props.isSelected ? '8px' : '0'};
        padding: 2px 0;
        min-height: 3.2em; /* Increased to accommodate two lines with descenders */
    }

    &:hover {
        ${props => !props.isSelected && `
            background: #ffffff;
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
            border-color: ${Colors.primaryDark}40;
        `}
    }
`;

const TravelerInput = styled.input`
    width: 60px;
    height: 24px;
    font-size: 14px;
    text-align: center;
    border: 1px solid ${Colors.primaryDark};
    border-radius: 3px;
    background: white;
    color: ${Colors.primaryDark};
    padding: 0 4px;
    
    &:focus {
        outline: none;
        border-color: ${Colors.primaryAccent};
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:placeholder-shown {
        color: #999;
    }
`;

const RemoveButton = styled.button`
    position: absolute;
    top: -8px;
    right: -8px;
    width: 24px;
    height: 24px;
    border: none;
    background: ${Colors.primaryDark};
    color: white;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
    
    &:hover, &:focus {
        background: #ff4444;
        transform: scale(1.2);
        box-shadow: 0 2px 8px rgba(255, 68, 68, 0.4);
    }

    &:active {
        transform: scale(0.95);
    }
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 4px;
`;

const InputLabel = styled.label`
    font-size: 12px;
    color: ${Colors.primaryDark};
`;

export default PassportGrid;