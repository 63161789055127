import styled from 'styled-components';
import { Button } from 'reactstrap';
import { Colors } from '../../../utils/budget-tool-specific';

// Main container styles
export const Container = styled.div`
    padding: 20px;
`;

export const ActionButtonContainer = styled.div`
    display: flex;
    gap: 10px;
    justify-content: flex-end;
`;

export const SeedButton = styled(Button)`
    background-color: ${Colors.primary};
    border: none;
    color: white;
    &:hover {
        background-color: ${Colors.primaryDark};
    }
`;

export const CardsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 10px;
`;

// Card styles
export const CardContainer = styled.div`
    background: ${props => props.isExpanded 
        ? `linear-gradient(145deg, ${Colors.primaryDark} 0%, #1a1a1a 100%)`
        : 'linear-gradient(145deg, #343434 0%, #1a1a1a 100%)'};
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    height: ${props => props.isExpanded ? 'auto' : '80px'};
    border: 1px solid ${props => props.isExpanded ? Colors.primaryAccent : 'transparent'};
    
    &:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        border-color: ${Colors.primaryAccent}80;
    }
`;

export const CardContent = styled.div`
    padding: 15px;
    color: white;
    opacity: ${props => props.isExpanded ? 1 : 0.9};
    transition: opacity 0.3s ease;

    &:hover {
        opacity: 1;
    }
`;

export const CardTitle = styled.h5`
    color: ${Colors.primaryAccent};
    margin-bottom: ${props => props.isExpanded ? '15px' : '0'};
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ExpandedContent = styled.div`
    margin-top: 15px;
    font-size: 14px;
`;

// Visa section styles
export const VisaSection = styled.div`
    margin-bottom: 20px;
    
    h6 {
        color: ${Colors.primaryAccent};
        margin-bottom: 10px;
    }
`;

export const PassportTags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 10px;
`;

export const Tag = styled.div`
    background: ${Colors.primaryAccent};
    color: ${Colors.primaryDark};
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 12px;
`;

export const TagDelete = styled.span`
    margin-left: 6px;
    cursor: pointer;
    font-weight: bold;
    &:hover {
        opacity: 0.7;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 15px;
`;

// Notes section styles
export const NotesSection = styled.div`
    margin-bottom: 20px;
    
    h6 {
        color: ${Colors.primaryAccent};
        margin-bottom: 10px;
    }
`;

export const NotesTextArea = styled.textarea`
    width: 100%;
    min-height: 100px;
    margin-bottom: 10px;
    background: white;
    color: ${Colors.primaryDark};
    border: 1px solid ${Colors.primaryLight};
    border-radius: 4px;
    padding: 8px;
    resize: vertical;

    &:focus {
        border-color: ${Colors.primaryAccent};
        box-shadow: 0 0 0 1px ${Colors.primaryAccent};
        outline: none;
    }

    &::placeholder {
        color: #888;
    }
`;

// Select styles for react-select
export const selectStyles = {
    control: (base) => ({
        ...base,
        background: 'white',
        borderColor: Colors.primaryLight,
    }),
    menu: (base) => ({
        ...base,
        background: 'white',
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused ? Colors.primaryLight : 'white',
        color: Colors.primaryDark,
        '&:hover': {
            backgroundColor: Colors.primaryLight,
        },
    }),
    singleValue: (base) => ({
        ...base,
        color: Colors.primaryDark,
    }),
    placeholder: (base) => ({
        ...base,
        color: '#666',
    }),
};