// settingsPassportCard.jsx
import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../../utils/budget-tool-specific';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SettingsPassportCard = ({ passport, onEdit, onDelete }) => {
    return (
        <Card>
            <CardContent>
                <CardTitle>{passport.country}</CardTitle>
                <CardDetails>
                    {passport.code && <Detail><Label>Code:</Label> {passport.code}</Detail>}
                    {passport.region && <Detail><Label>Region:</Label> {passport.region}</Detail>}
                </CardDetails>
                {passport.notes && (
                    <Notes>
                        <Label>Notes:</Label>
                        <NoteText>{passport.notes}</NoteText>
                    </Notes>
                )}
                <Actions>
                    <ActionButton 
                        type="button" 
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onEdit();
                        }}
                    >
                        <FontAwesomeIcon icon={['fas', 'pen']} />
                    </ActionButton>
                    <ActionButton 
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onDelete();
                        }}
                    >
                        <FontAwesomeIcon icon={['fas', 'trash']} />
                    </ActionButton>
                </Actions>
            </CardContent>
        </Card>
    );
};

const Card = styled.div`
    background: linear-gradient(145deg, #343434 0%, #1a1a1a 100%);
    border-radius: 8px;
    border: 1px solid transparent;
    transition: all 0.3s ease;
    height: auto;
    min-height: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    &:hover {
        border-color: ${Colors.primaryAccent}80;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
`;

const CardContent = styled.div`
    padding: 15px;
    color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const CardTitle = styled.h5`
    color: ${Colors.primaryAccent};
    margin-bottom: 12px;
    font-size: 18px;
`;

const CardDetails = styled.div`
    margin-bottom: 12px;
`;

const Detail = styled.div`
    margin-bottom: 4px;
    font-size: 14px;
    display: flex;
    gap: 8px;
`;

const Label = styled.span`
    color: ${Colors.primaryAccent};
    font-weight: 500;
`;

const Notes = styled.div`
    margin-top: 8px;
    font-size: 14px;
    flex-grow: 1;
`;

const NoteText = styled.p`
    margin: 4px 0 0;
    color: ${Colors.primaryLight};
    font-size: 13px;
`;

const Actions = styled.div`
    display: flex;
    gap: 8px;
    margin-top: auto;
    justify-content: flex-end;
`;

const ActionButton = styled.button`
    background: transparent;
    border: none;
    color: ${Colors.primaryAccent};
    padding: 4px 8px;
    cursor: pointer;
    transition: color 0.2s ease;
    type: "button"; /* Explicitly set type to button */

    &:hover {
        color: white;
    }
`;

export default SettingsPassportCard;