// visaWeightsManager.jsx
import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'reactstrap';
import { Colors } from '../../../utils/budget-tool-specific';

const VisaWeightsManager = ({ weights, onWeightChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <WeightsContainer>
      <HeaderRow onClick={() => setIsExpanded(!isExpanded)}>
        <div>
          <h5>Visa Requirement Weights</h5>
          <p className="text-muted">Configure how different visa types affect accessibility scores</p>
        </div>
        <ExpandButton expanded={isExpanded}>▼</ExpandButton>
      </HeaderRow>
      
      {isExpanded && (
        <WeightGrid>
          <WeightTable>
            <tbody>
              <WeightRow>
                <WeightLabel>No Visa Required</WeightLabel>
                <WeightCell>
                  <WeightInput
                    type="number"
                    min="0"
                    max="1000"
                    value={weights.noVisa}
                    onChange={(e) => onWeightChange('noVisa', parseFloat(e.target.value))}
                  />
                </WeightCell>
              </WeightRow>
              
              <WeightRow>
                <WeightLabel>Visa on Arrival</WeightLabel>
                <WeightCell>
                  <WeightInput
                    type="number"
                    min="0"
                    max="1000"
                    value={weights.visaOnArrival}
                    onChange={(e) => onWeightChange('visaOnArrival', parseFloat(e.target.value))}
                  />
                </WeightCell>
              </WeightRow>
              
              <WeightRow>
                <WeightLabel>eVisa Required</WeightLabel>
                <WeightCell>
                  <WeightInput
                    type="number"
                    min="0"
                    max="1000"
                    value={weights.eVisa}
                    onChange={(e) => onWeightChange('eVisa', parseFloat(e.target.value))}
                  />
                </WeightCell>
              </WeightRow>
              
              <WeightRow>
                <WeightLabel>Paper Visa Required</WeightLabel>
                <WeightCell>
                  <WeightInput
                    type="number"
                    min="0"
                    max="1000"
                    value={weights.paperVisa}
                    onChange={(e) => onWeightChange('paperVisa', parseFloat(e.target.value))}
                  />
                </WeightCell>
              </WeightRow>

              <WeightRow>
                <WeightLabel>Restricted</WeightLabel>
                <WeightCell>
                  <WeightInput
                    type="number"
                    min="0"
                    max="1000"
                    value={weights.restricted}
                    onChange={(e) => onWeightChange('restricted', parseFloat(e.target.value))}
                  />
                </WeightCell>
              </WeightRow>
            </tbody>
          </WeightTable>
          <WeightHelper>Higher weights indicate better accessibility. Default: No Visa (1000), VOA (100), eVisa (10), Paper (1), Restricted (0)</WeightHelper>
        </WeightGrid>
      )}
    </WeightsContainer>
  );
};

const WeightsContainer = styled.div`
  background: white;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  border: 1px solid ${Colors.primaryLight}40;
`;

const HeaderRow = styled.div`
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background 0.2s ease;
  background: ${Colors.primaryLight}10;

  &:hover {
    background: ${Colors.primaryLight}20;
  }

  h5 {
    margin: 0;
    color: ${Colors.primaryDark};
    font-size: 16px;
  }

  p {
    font-size: 12px;
    margin: 4px 0 0 0;
    color: #666;
  }
`;

const ExpandButton = styled.span`
  color: ${Colors.primaryDark};
  transform: ${props => props.expanded ? 'rotate(180deg)' : 'rotate(0)'};
  transition: transform 0.2s ease;
  font-size: 12px;
`;

const WeightGrid = styled.div`
  padding: 15px 20px;
  border-top: 1px solid ${Colors.primaryLight}20;
  background: white;
`;

const WeightTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const WeightRow = styled.tr`
  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.primaryLight}10;
  }
`;

const WeightLabel = styled.td`
  padding: 8px 0;
  font-size: 13px;
  color: ${Colors.primaryDark};
  width: 70%;
`;

const WeightCell = styled.td`
  padding: 8px 0;
  width: 30%;
  text-align: right;
`;

const WeightInput = styled(Input)`
  width: 80px;
  height: 28px;
  text-align: center;
  border: 1px solid ${Colors.primaryLight}40;
  font-size: 13px;
  padding: 4px 8px;
  background: ${Colors.primaryLight}05;
  
  &:focus {
    border-color: ${Colors.primaryAccent};
    box-shadow: 0 0 0 1px ${Colors.primaryAccent}20;
    background: white;
  }

  &:hover {
    border-color: ${Colors.primaryAccent}60;
    background: white;
  }
`;

const WeightHelper = styled.div`
  font-size: 11px;
  color: #666;
  margin-top: 12px;
  text-align: center;
  padding-top: 12px;
  border-top: 1px dashed ${Colors.primaryLight}20;
  background: ${Colors.primaryLight}05;
  margin: 12px -20px -15px -20px;
  padding: 12px 20px 15px 20px;
`;

export default VisaWeightsManager;