import React from 'react';
import Select from 'react-select';
import { VisaSection, PassportTags, Tag, TagDelete } from './commonComponents';
import { Colors } from '../../../utils/budget-tool-specific';

const PassportList = ({ title, fieldName, passportIds, country, passports, onPassportSelect, onPassportRemove }) => {
    const getUsedPassports = () => {
        const usedPassports = new Set();
        ['noVisaRequired', 'visaOnArrival', 'eVisaRequired', 'paperVisaRequired', 'restrictedPassports'].forEach(field => {
            if (country[field]) {
                country[field].forEach(id => usedPassports.add(id));
            }
        });
        return usedPassports;
    };

    const filterAvailablePassports = (passports) => {
        const usedPassports = getUsedPassports();
        return passports.filter(passport => !usedPassports.has(passport.value));
    };

    const getPassportNames = (ids) => {
        if (!ids) return [];
        return ids.map(id => {
            const passport = passports.find(p => p.value === id);
            return passport ? passport.label : id;
        });
    };

    return (
        <VisaSection>
            <h6>{title}</h6>
            <Select
                value={null}
                onChange={(option) => onPassportSelect(option, fieldName, country)}
                options={filterAvailablePassports(passports)}
                placeholder="Add passport..."
                styles={{
                    control: (base) => ({
                        ...base,
                        background: 'white',
                        borderColor: Colors.primaryLight,
                    }),
                    menu: (base) => ({
                        ...base,
                        background: 'white',
                    }),
                    option: (base, state) => ({
                        ...base,
                        backgroundColor: state.isFocused ? Colors.primaryLight : 'white',
                        color: Colors.primaryDark,
                        '&:hover': {
                            backgroundColor: Colors.primaryLight,
                        },
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: Colors.primaryDark,
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: '#666',
                    }),
                }}
            />
            <PassportTags>
                {getPassportNames(passportIds).map((name, index) => (
                    <Tag key={index}>
                        {name}
                        <TagDelete onClick={() => onPassportRemove(passportIds[index], fieldName, country)}>
                            ×
                        </TagDelete>
                    </Tag>
                ))}
            </PassportTags>
        </VisaSection>
    );
};

export default PassportList;