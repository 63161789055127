// PassportComparison.jsx
import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors, PrimaryButton } from '../../../utils/budget-tool-specific';

const PassportComparison = ({ comparisonResults, onClose, visaWeights, selectedPassports, countries }) => {
    const [showAllResults, setShowAllResults] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [filters, setFilters] = useState({
        noVisa: true,
        visaOnArrival: true,
        eVisa: true,
        paperVisa: true,
        restricted: false
    });

    console.log('PassportComparison props:', {
        comparisonResults,
        visaWeights,
        selectedPassports
    });

    // Function to format the weighted score with a K suffix for thousands
    const formatScore = (score) => {
        if (score >= 1000) {
            return `${(score / 1000).toFixed(1)}K`;
        }
        return score;
    };

    const handleFilterChange = (filterType) => {
        setFilters(prev => ({
            ...prev,
            [filterType]: !prev[filterType]
        }));
    };

    const getFilteredResults = () => {
        return comparisonResults.filter(result => {
            if (result.noVisaCount > 0 && !filters.noVisa) return false;
            if (result.visaOnArrivalCount > 0 && !filters.visaOnArrival) return false;
            if (result.eVisaCount > 0 && !filters.eVisa) return false;
            if (result.paperVisaCount > 0 && !filters.paperVisa) return false;
            return true;
        });
    };

    return (
        <ResultsContainer>
            <ResultsHeader>
                <h4>Best Destinations for Selected Passports</h4>
                <CloseButton onClick={onClose}>×</CloseButton>
            </ResultsHeader>

            <FilterContainer>
                <FilterLabel>Filter by Visa Status:</FilterLabel>
                <FilterOptions>
                    <FilterOption>
                        <input
                            type="checkbox"
                            checked={filters.noVisa}
                            onChange={() => handleFilterChange('noVisa')}
                        />
                        <span style={{ color: '#4CAF50' }}>No Visa Required</span>
                    </FilterOption>
                    <FilterOption>
                        <input
                            type="checkbox"
                            checked={filters.visaOnArrival}
                            onChange={() => handleFilterChange('visaOnArrival')}
                        />
                        <span style={{ color: '#FFC107' }}>Visa On Arrival</span>
                    </FilterOption>
                    <FilterOption>
                        <input
                            type="checkbox"
                            checked={filters.eVisa}
                            onChange={() => handleFilterChange('eVisa')}
                        />
                        <span style={{ color: '#FF9800' }}>eVisa/ETA Required</span>
                    </FilterOption>
                    <FilterOption>
                        <input
                            type="checkbox"
                            checked={filters.paperVisa}
                            onChange={() => handleFilterChange('paperVisa')}
                        />
                        <span style={{ color: '#F44336' }}>Paper Visa Required</span>
                    </FilterOption>
                </FilterOptions>
            </FilterContainer>

            <ContentWrapper>
                <ResultsGrid>
                    {getFilteredResults()
                        .slice(0, showAllResults ? undefined : 5)
                        .map((result, index) => (
                        <ResultCard 
                            key={index} 
                            accessibilityScore={result.accessibilityScore}
                            isSelected={selectedCard === index}
                            onClick={() => setSelectedCard(selectedCard === index ? null : index)}
                        >
                            <CountryName>
                                {result.country}
                                <ScoreBadge>
                                    {formatScore(result.accessibilityScore)}
                                </ScoreBadge>
                            </CountryName>
                            
                            <AccessibilityIndicator>
                                {result.noVisaCount > 0 && (
                                    <AccessCount color="#4CAF50">
                                        {result.noVisaCount} No Visa
                                    </AccessCount>
                                )}
                                {result.visaOnArrivalCount > 0 && (
                                    <AccessCount color="#FFC107">
                                        {result.visaOnArrivalCount} Visa on Arrival
                                    </AccessCount>
                                )}
                                {result.eVisaCount > 0 && (
                                    <AccessCount color="#FF9800">
                                        {result.eVisaCount} eVisa
                                    </AccessCount>
                                )}
                                {result.paperVisaCount > 0 && (
                                    <AccessCount color="#F44336">
                                        {result.paperVisaCount} Paper Visa
                                    </AccessCount>
                                )}
                            </AccessibilityIndicator>
                        </ResultCard>
                    ))}
                </ResultsGrid>

                {selectedCard !== null && (
                    <DetailsPanel>
                        <PanelHeader>
                            <h5>{getFilteredResults()[selectedCard].country}</h5>
                            <CloseButton onClick={() => setSelectedCard(null)}>×</CloseButton>
                        </PanelHeader>
                        
                        <PanelContent>
                            <DetailHeader>Visa Requirements Breakdown</DetailHeader>
                            <Result 
                                result={{
                                    ...getFilteredResults()[selectedCard],
                                    allCountries: countries
                                }}
                                visaWeights={visaWeights}
                                selectedPassports={selectedPassports}
                            />
                        </PanelContent>
                    </DetailsPanel>
                )}
            </ContentWrapper>

            {getFilteredResults().length > 5 && (
                <ShowMoreButton onClick={() => setShowAllResults(!showAllResults)}>
                    {showAllResults ? 'Show Less' : `Show ${getFilteredResults().length - 5} More Countries`}
                </ShowMoreButton>
            )}
        </ResultsContainer>
    );
};

// Separate component for the result details
const Result = ({ result, visaWeights, selectedPassports }) => {
    console.log('Result props:', {
        result,
        visaWeights,
        selectedPassports
    });

    // Get all passport IDs from the result's visa requirement sections
    const foundPassportIds = new Set([
        ...result.noVisa.map(p => p.country),
        ...result.visaOnArrival.map(p => p.country),
        ...result.eVisa.map(p => p.country),
        ...result.paperVisa.map(p => p.country)
    ]);

    console.log('Found passport IDs:', foundPassportIds);

    // Convert country codes to country names using the countries data
    const countryCodeToName = new Map(
        result.allCountries?.map(country => [country.id, country.country]) || []
    );

    // Get all passports that weren't found in any visa requirement section
    const notFoundPassports = selectedPassports?.filter(passportCode => {
        const countryName = countryCodeToName.get(passportCode);
        return countryName && !foundPassportIds.has(countryName);
    }).map(passportCode => countryCodeToName.get(passportCode)) || [];

    console.log('Not found passports:', notFoundPassports);

    return (
        <>
            {result.noVisa.length > 0 && (
                <DetailSection>
                    <DetailTitle color="#4CAF50">No Visa Required</DetailTitle>
                    <DetailContent>
                        <CountriesList>
                            {result.noVisa.map((passport, i) => (
                                <DetailChip key={i} color="#4CAF50">
                                    {passport.country} (Weight: {passport.weight})
                                </DetailChip>
                            ))}
                        </CountriesList>
                        <WeightCalculation>
                            Base Weight: {result.noVisaWeight}
                            <br />
                            Multiplier: {visaWeights?.noVisa || 1000}
                            <br />
                            Total: {result.noVisaWeight * (visaWeights?.noVisa || 1000)}
                        </WeightCalculation>
                    </DetailContent>
                </DetailSection>
            )}
            
            {result.visaOnArrival.length > 0 && (
                <DetailSection>
                    <DetailTitle color="#FFC107">Visa on Arrival</DetailTitle>
                    <DetailContent>
                        <CountriesList>
                            {result.visaOnArrival.map((passport, i) => (
                                <DetailChip key={i} color="#FFC107">
                                    {passport.country} (Weight: {passport.weight})
                                </DetailChip>
                            ))}
                        </CountriesList>
                        <WeightCalculation>
                            Base Weight: {result.visaOnArrivalWeight}
                            <br />
                            Multiplier: {visaWeights?.visaOnArrival || 100}
                            <br />
                            Total: {result.visaOnArrivalWeight * (visaWeights?.visaOnArrival || 100)}
                        </WeightCalculation>
                    </DetailContent>
                </DetailSection>
            )}
            
            {result.eVisa.length > 0 && (
                <DetailSection>
                    <DetailTitle color="#FF9800">eVisa Required</DetailTitle>
                    <DetailContent>
                        <CountriesList>
                            {result.eVisa.map((passport, i) => (
                                <DetailChip key={i} color="#FF9800">
                                    {passport.country} (Weight: {passport.weight})
                                </DetailChip>
                            ))}
                        </CountriesList>
                        <WeightCalculation>
                            Base Weight: {result.eVisaWeight}
                            <br />
                            Multiplier: {visaWeights?.eVisa || 10}
                            <br />
                            Total: {result.eVisaWeight * (visaWeights?.eVisa || 10)}
                        </WeightCalculation>
                    </DetailContent>
                </DetailSection>
            )}
            
            {result.paperVisa.length > 0 && (
                <DetailSection>
                    <DetailTitle color="#F44336">Paper Visa Required</DetailTitle>
                    <DetailContent>
                        <CountriesList>
                            {result.paperVisa.map((passport, i) => (
                                <DetailChip key={i} color="#F44336">
                                    {passport.country} (Weight: {passport.weight})
                                </DetailChip>
                            ))}
                        </CountriesList>
                        <WeightCalculation>
                            Base Weight: {result.paperVisaWeight}
                            <br />
                            Multiplier: {visaWeights?.paperVisa || 1}
                            <br />
                            Total: {result.paperVisaWeight * (visaWeights?.paperVisa || 1)}
                        </WeightCalculation>
                    </DetailContent>
                </DetailSection>
            )}

            {notFoundPassports.length > 0 && (
                <DetailSection>
                    <DetailTitle color="#9E9E9E">Not Found in Visa Requirements</DetailTitle>
                    <DetailContent>
                        <CountriesList>
                            {notFoundPassports.map((passport, i) => (
                                <DetailChip key={i} color="#9E9E9E">
                                    {passport}
                                </DetailChip>
                            ))}
                        </CountriesList>
                    </DetailContent>
                </DetailSection>
            )}
            
            <TotalCalculation>
                Total Score: <strong>{result.accessibilityScore}</strong>
            </TotalCalculation>
        </>
    );
};

// Styled components for PassportComparison...
const ResultsContainer = styled.div`
    margin: 20px 0;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ResultsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    
    h4 {
        margin: 0;
        color: ${Colors.primaryDark};
    }
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 24px;
    color: ${Colors.primaryDark};
    cursor: pointer;
    padding: 0 8px;
    
    &:hover {
        color: ${Colors.primaryAccent};
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    gap: 20px;
    position: relative;
    align-items: flex-start;
`;

const ResultsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    flex: 1;
    min-width: 0;
`;

const ResultCard = styled.div`
    padding: 15px;
    background: #f8f9fa;
    border-radius: 6px;
    border-left: 4px solid ${props => {
        if (props.accessibilityScore >= 1000) return '#4CAF50';
        if (props.accessibilityScore >= 100) return '#2196F3';
        if (props.accessibilityScore >= 10) return '#FFC107';
        return '#FF5722';
    }};
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box;
    
    &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        transform: translateY(-2px);
    }
`;

const CountryName = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: ${Colors.primaryDark};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ScoreBadge = styled.span`
    background: ${Colors.primaryAccent};
    color: ${Colors.primaryDark};
    padding: 3px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: bold;
`;

const AccessibilityIndicator = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
`;

const AccessCount = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: ${props => props.color};
    background: ${props => props.color}10;
    padding: 2px 6px;
    border-radius: 4px;
    border: 1px solid ${props => props.color}40;
`;

const DetailsPanel = styled.div`
    width: 600px;
    background: linear-gradient(135deg, #2C2C2C 0%, #3C3C3C 100%);
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    position: sticky;
    top: 20px;
    height: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.1);
`;

const PanelHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: linear-gradient(135deg, #1A1A1A 0%, #2C2C2C 100%);
    border-radius: 12px 12px 0 0;
    
    h5 {
        margin: 0;
        color: #FFFFFF;
        font-size: 24px;
        font-weight: 600;
    }
`;

const PanelContent = styled.div`
    padding: 25px;
    color: #FFFFFF;
`;

const DetailHeader = styled.h6`
    margin-bottom: 20px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
`;

const DetailSection = styled.div`
    margin-bottom: 20px;
    padding: 15px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
`;

const DetailTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: ${props => props.color};
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 8px;

    &::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        background: ${props => props.color};
        border-radius: 50%;
    }
`;

const DetailContent = styled.div`
    display: flex;
    gap: 20px;
    margin-bottom: 0;
`;

const CountriesList = styled.div`
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    min-width: 0;
`;

const DetailChip = styled.span`
    font-size: 13px;
    color: ${props => props.color};
    background: ${props => props.color}15;
    padding: 2px 8px;
    border-radius: 12px;
    border: 1px solid ${props => props.color}40;
    font-weight: 500;
    line-height: 1.2;
    height: 24px;
    display: inline-flex;
    align-items: center;
`;

const WeightCalculation = styled.div`
    font-size: 13px;
    color: #CCCCCC;
    padding: 10px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    border: 1px dashed rgba(255, 255, 255, 0.2);
    line-height: 1.5;
    min-width: 180px;
    flex-shrink: 0;
`;

const TotalCalculation = styled.div`
    margin-top: 20px;
    padding: 15px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    font-size: 18px;
    text-align: right;
    color: #FFFFFF;
    font-weight: 600;
    border: 1px solid rgba(255, 255, 255, 0.1);

    strong {
        color: ${Colors.primaryAccent};
        font-size: 24px;
    }
`;

const ShowMoreButton = styled(PrimaryButton)`
    margin: 20px auto 0;
    display: block;
`;

const FilterContainer = styled.div`
    margin-bottom: 20px;
    padding: 15px;
    background: ${Colors.primaryDark}10;
    border-radius: 8px;
`;

const FilterLabel = styled.div`
    margin-bottom: 10px;
    color: ${Colors.primaryDark};
    font-weight: bold;
`;

const FilterOptions = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
`;

const FilterOption = styled.label`
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${Colors.primaryDark};
    cursor: pointer;

    input[type="checkbox"] {
        width: 16px;
        height: 16px;
    }
`;

export default PassportComparison;