import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Container, Modal, ModalHeader, ModalBody, ModalFooter, Input, Button } from "reactstrap";
import { HeaderTitle, Spacer, PrimaryButton, Colors } from "../../../utils/budget-tool-specific";
import { useParams } from "react-router-dom";
import PageTemplate from "../page-template";
import PassportGrid from "./passportGrid";
import { GetDB } from "../../../utils/database/firebase-budget-tool";
import { listenToDocument, updateDocumentProperty } from "../../../utils/firestore-functions";

const DisclaimerText = styled.div`
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 15px;
    margin: 15px 0;
    font-size: 14px;
    color: #666;
    line-height: 1.5;
`;

const PassportEditComponent = (props) => {
    const budgetDB = GetDB();
    const [passportData, setPassportData] = useState(null);
    const [savedModal, setSavedModal] = useState(false);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [titleInput, setTitleInput] = useState("");
    const [copiedModal, setCopiedModal] = useState(false);

    useEffect(() => {
        var unsubscribe = listenToDocument(budgetDB, "passportGuides", props.experience, SetPassport);
        return unsubscribe;
    }, []);

    const SetPassport = (passport) => {
        if (passport) {
            // Reconstruct the data structure
            const selectedCountries = (passport.selectedCountries || []).map(item => 
                typeof item === 'string' ? item : item.countryId
            );
            
            const travelerCounts = (passport.selectedCountries || []).reduce((acc, item) => {
                if (typeof item === 'object') {
                    acc[item.countryId] = item.travelers;
                }
                return acc;
            }, {});

            const weights = (passport.selectedCountries || []).reduce((acc, item) => {
                if (typeof item === 'object') {
                    acc[item.countryId] = item.weight;
                }
                return acc;
            }, {});

            console.log('Setting passport data:', {
                selectedCountries,
                travelerCounts,
                weights
            });

            setPassportData({
                ...passport,
                selectedCountries,
                travelerCounts,
                weights
            });
            setTitleInput(passport.title || "");
        } else {
            setPassportData(passport);
        }
    };

    const handleTitleChange = (e) => {
        setTitleInput(e.target.value);
    };

    const handleTitleBlur = () => {
        setIsEditingTitle(false);
        if (titleInput.trim() !== passportData.title) {
            setPassportData(prev => ({ ...prev, title: titleInput.trim() }));
        }
    };

    const handleTitleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleTitleBlur();
        }
    };

    const handleSaveChanges = async (changes) => {
        try {
            console.log('Saving passport changes:', changes);
            
            // Update the passport data in the state
            setPassportData(prev => ({
                ...prev,
                ...changes
            }));

            // Update the document in Firestore
            await updateDocumentProperty(
                budgetDB,
                "passportGuides",
                props.experience,
                changes
            );

            setSavedModal(true);
        } catch (error) {
            console.error('Error saving passport changes:', error);
        }
    };

    const backToGuideList = () => {
        window.location = "/budget-app/passport";
    };

    const saveForm = () => {
        if (passportData) {
            console.log('Saving passport data:', passportData);
            // Save the title
            updateDocumentProperty(budgetDB, "passportGuides", props.experience, "title", passportData.title);
            
            // Save the selected countries with their traveler counts and weights
            const selectedCountriesWithDetails = (passportData.selectedCountries || []).map(countryId => ({
                countryId: countryId,
                travelers: passportData.travelerCounts?.[countryId] || 1,
                weight: passportData.weights?.[countryId] || 1
            }));

            console.log('Saving selected countries with details:', selectedCountriesWithDetails);

            updateDocumentProperty(
                budgetDB, 
                "passportGuides", 
                props.experience, 
                "selectedCountries", 
                selectedCountriesWithDetails,
                () => setSavedModal(true)
            );
        }
    };

    const copyClientUrl = () => {
        const clientUrl = `${window.location.origin}/budget-app/passport/${props.experience}`;
        navigator.clipboard.writeText(clientUrl).then(() => {
            setCopiedModal(true);
        });
    };

    return (
        <React.Fragment>
            <Spacer height="60px" id="height-spacer"/>
            <HeaderTitle>
                {isEditingTitle ? (
                    <Input
                        type="text"
                        value={titleInput}
                        onChange={handleTitleChange}
                        onBlur={handleTitleBlur}
                        onKeyPress={handleTitleKeyPress}
                        autoFocus
                    />
                ) : (
                    <span onClick={() => setIsEditingTitle(true)}>
                        {passportData?.title || "Loading..."}
                    </span>
                )}
            </HeaderTitle>
            <DisclaimerText>
                Information is provided as guidance only and accurate at the time of publishing. Always check government websites and airline materials before booking and traveling.
            </DisclaimerText>
            <Row>
                {!props.client &&
                <Col style={{textAlign: "right"}}>
                    <PrimaryButton onClick={copyClientUrl} style={{marginRight: "10px"}}>
                        Copy Client URL
                    </PrimaryButton>
                    <PrimaryButton onClick={saveForm}>
                        Save Changes
                    </PrimaryButton>
                </Col>
                }
            </Row>
            <PassportGrid 
                passportData={passportData} 
                setPassportData={setPassportData}
                client={props.client}
            />
            <Modal isOpen={savedModal} toggle={() => setSavedModal(false)}>
                <ModalHeader toggle={() => setSavedModal(false)}>Success</ModalHeader>
                <ModalBody>
                    Changes saved successfully!
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => setSavedModal(false)}>OK</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={copiedModal} toggle={() => setCopiedModal(false)}>
                <ModalHeader toggle={() => setCopiedModal(false)}>Success</ModalHeader>
                <ModalBody>
                    Client URL copied to clipboard!
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => setCopiedModal(false)}>OK</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

const PassportEdit = (props) => {
    let { experience } = useParams();
    if (experience === undefined) {
        window.history.replaceState(null, "", "/budget-app/passport");
    }

    if (props.collapsed) { 
        return <PassportEditComponent experience={experience} client={props.client}/>
    } else {
        return <PageTemplate component={<PassportEditComponent experience={experience} client={props.client}/>} client={props.client}/> 
    }
};

export default PassportEdit;

const StyledContainer = styled(Container)`
    padding: 32px;
`;

const TitleInput = styled(Input)`
    font-size: 24px;
    font-weight: bold;
    color: ${Colors.primaryDark};
    border: 2px solid ${Colors.primaryAccent};
    padding: 8px 12px;
    margin-top: 30px;
    width: 100%;
    max-width: 600px;
    
    &:focus {
        box-shadow: none;
        border-color: ${Colors.primaryAccent};
    }
`; 