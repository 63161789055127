import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Modal, ModalBody, ModalHeader, ModalFooter, Button, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { WhiteForm, OutputRow, BoldLabel, PrimaryButton, EditIcon, PinkButtonContainer, Colors } from "../../../utils/budget-tool-specific";
import { GetDB, generateGUID } from "../../../utils/database/firebase-budget-tool";
import { listenToCollection, setDocument, updateDocumentProperty, deleteDocument } from "../../../utils/firestore-functions";
import firebase from "firebase/compat/app";

const PassportList = () => {
    const [passportList, setPassportList] = useState({});
    const [sortedList, setSortedList] = useState({});
    const [deleteID, setDeleteID] = useState("");
    const [modal, setModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [recordsPerPage] = useState(15);

    const budgetDB = GetDB();
    const nPages = Math.ceil(Object.entries(sortedList).length / recordsPerPage);

    useEffect(() => {
        var unsubscribe = listenToCollection(budgetDB, "passportGuides", SetLists);
        return unsubscribe;
    }, []);

    const SetLists = (passports) => {
        let x = passports.reduce((accumulator, value) => {
            return { ...accumulator, [value.id]: value };
        }, {});
        setPassportList(x);
        setSortedList(Object.fromEntries(Object.entries(x).sort(function(a, b) {
            return b[1].lastModification - a[1].lastModification;
        })));
    };

    const openPassport = (id) => {
        const timeStamp = firebase.firestore.Timestamp.now().toDate();
        updateDocumentProperty(budgetDB, "passportGuides", id, "lastModification", timeStamp);
        window.location = "/budget-app/passport/edit/" + id;
    };

    const createNewPassport = () => {
        const newTitle = "Passport Guide " + (Object.keys(passportList).length + 1);
        const timeStamp = firebase.firestore.Timestamp.now().toDate();

        let newPassport = {
            title: newTitle,
            countries: [],
            lastModification: timeStamp,
            id: generateGUID(),
        };

        const openNewPassport = () => {
            openPassport(newPassport.id);
        };

        setDocument(budgetDB, "passportGuides", newPassport.id, newPassport, openNewPassport);
    };

    const copyPassport = (passport) => {
        const newTitle = passport.title + " (Copy)";
        const timeStamp = firebase.firestore.Timestamp.now().toDate();

        let newPassport = {
            ...passport,
            title: newTitle,
            lastModification: timeStamp,
            id: generateGUID(),
        };

        const openNewPassport = () => {
            openPassport(newPassport.id);
        };

        setDocument(budgetDB, "passportGuides", newPassport.id, newPassport, openNewPassport);
    };

    const deletePassport = async (id) => {
        toggleDeleteModal();
        deleteDocument(budgetDB, "passportGuides", deleteID);
    };

    const confirmDelete = (id) => {
        toggleDeleteModal();
        setDeleteID(id);
    };

    const toggleDeleteModal = () => setModal(!modal);

    const navigateToPage = (e, i) => {
        setCurrentPage(i);
    };

    return (
        <React.Fragment>
            <WhiteForm>
                <Row>
                    <Col>
                        <BoldLabel>Passport Guides</BoldLabel>
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                        <PrimaryButton onClick={() => createNewPassport()}>
                            Create New Guide
                        </PrimaryButton>
                    </Col>
                </Row>
                <OutputRow>
                    <Col>
                        <p>Title</p>
                    </Col>
                    <Col>
                        <p>Last Modified</p>
                    </Col>
                    <Col style={{ textAlign: "right", paddingRight: "30px" }}>
                        <p>Actions</p>
                    </Col>
                </OutputRow>
                {Object.entries(sortedList)
                    .slice(currentPage * recordsPerPage,
                        (currentPage + 1) * recordsPerPage).map((passport, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <OutputRow>
                                        <Col>
                                            <p>{passport[1].title}</p>
                                        </Col>
                                        <Col>
                                            <p>{(passport[1].lastModification !== undefined && passport[1].lastModification !== "") ?
                                                passport[1].lastModification.toDate().toLocaleString("en-US") :
                                                ""}</p>
                                        </Col>
                                        <Col style={{ textAlign: "right", paddingRight: "30px" }}>
                                            <PinkButtonContainer onClick={() => openPassport(passport[1].id)}>
                                                <EditIcon icon={['fas', 'pen']} style={{ height: "25px", paddingLeft: "2px" }} />
                                            </PinkButtonContainer>
                                            <PinkButtonContainer onClick={() => copyPassport(passport[1])}>
                                                <EditIcon icon={['fas', 'copy']} style={{ height: "25px" }} />
                                            </PinkButtonContainer>
                                            <PinkButtonContainer onClick={() => confirmDelete(passport[1].id)}>
                                                <EditIcon icon={['fas', 'trash']} style={{ height: "25px" }} />
                                            </PinkButtonContainer>
                                        </Col>
                                    </OutputRow>
                                </React.Fragment>
                            );
                        })}
                <Modal isOpen={modal} toggle={toggleDeleteModal}>
                    <ModalHeader toggle={toggleDeleteModal}>Delete Guide</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this guide?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleDeleteModal}>Cancel</Button>
                        <Button color="danger" onClick={() => deletePassport(deleteID)}>Delete</Button>
                    </ModalFooter>
                </Modal>
            </WhiteForm>
        </React.Fragment>
    );
};

export default PassportList; 